import React, { useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap"
function PHRawResponseModal(props) {
  const { rawResponse, toggle, isOpen } = props
  useEffect(() => {
    if (rawResponse) {
      // console.log(JSON.parse(rawResponse))
    }
  }, [rawResponse])
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <Label>Raw Response</Label>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col>
                {/* {rawResponse
                  .split(/(?:^|,)((?:[^\",]|\"[^\"]*\")*)/)
                  .map((value, index) => {
                    return (
                      <Row key={index}>
                        <Col>
                          {value}
                          {index !==
                            rawResponse.split(/(?:^|,)((?:[^\",]|\"[^\"]*\")*)/)
                              .length -
                              2 && value !== ""
                            ? ","
                            : ""}
                        </Col>
                      </Row>
                    )
                  })} */}
                {rawResponse ? (
                  <pre>{JSON.stringify(JSON.parse(rawResponse), null, 2)}</pre>
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}

export default PHRawResponseModal
