import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Col, Table, Label } from "reactstrap"

const RefundFailInvoice = props => {
  const { amount, currency, paymentRequestDetails } = props
  // console.log(merchantDetails)
  return (
    <>
      <Col lg="2"></Col>
      <Col lg="8">
        <div
          style={{
            background: "#EAEFF5",
            padding: "2%",
            borderRadius: "25px",
          }}
        >
          <h1>
            <Label className="mx-auto" style={{ ["color"]: "#ce0058" }}>
              {/* <i className="bx bx-unlink fa-2x" />  */}
              <br></br>
              {props.t("REFUND REJECTED")}
            </Label>
          </h1>
          <p>
            {props.t("For more details, kindly contact")}
            <br />
            <b>{paymentRequestDetails?.createdByEmail}</b>
            {/* {props.t("Kindly contact ")} <b>{merchantDetails?.merchantName}</b>{" "}
            {props.t(" for more details")} */}
          </p>

          <Table
            style={{ textAlign: "center" }}
            className="table table-sm  table-borderless"
          >
            <tbody>
              <tr>
                <td>
                  <b>
                    {props.t("AMOUNT DUE")} {props.t(currency)}{" "}
                    {amount.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
      <Col lg="2"> </Col>
    </>
  )
}

RefundFailInvoice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(RefundFailInvoice))
