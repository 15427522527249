import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"

import AuthUser from "components/AuthUser/AuthUser"
import { CommissionSettings } from "./CommissionSettings"
import { v4 as uuidv4 } from "uuid"
import { CustomConfig } from "./CustomConfig"
import TransactionFeeSettings from "./TransactionFeeSettings"

export const PaymentGateways = props => {
  const {
    paymentGateway,
    handlePaymentGatewayChange,
    handlePaymentGatewayDelete,
    paymentGatewayList,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
  } = props

  const [selectedPg, setSelectedPg] = useState({})
  const [commissionBasis, setCommissionBasis] = useState([])
  const [transactionBasis, setTransactionBasis] = useState([])
  const user = useState(AuthUser())
  const inputPaymentGateway = "paymentGateway" + index
  // const inputTerminalId = "TerminalId" + index
  // const inputPassword = "Password" + index
  // const inputUserName = "UserName" + index
  // const inputTerminalPassword = "TerminalPassword" + index
  const inputMerchantId = "merchantId" + index
  const inputIsActive = "isActive" + index
  const inputCommissionBasedOn = "commissionBasedOn" + index

  const [pgList, setPgList] = useState([])
  useEffect(() => {
    // console.log(selectedPg)
  }, [selectedPg])

  useEffect(() => {
    // console.log(commissionBasis)
  }, [commissionBasis])
  useEffect(() => {
    // console.log(transactionBasis)
  }, [transactionBasis])

  useEffect(() => {
    if (paymentGatewayList) {
      setPgList(paymentGatewayList)
    }
  }, [paymentGatewayList])

  useEffect(() => {
    // console.log(paymentGateway)
  }, [paymentGateway])
  useEffect(() => {
    if (selectedPg && paymentGateway.commissionBasedOn === "payment type") {
      setCommissionBasis(selectedPg?.paymentType)
    }

    if (selectedPg && paymentGateway.commissionBasedOn === "card type") {
      setCommissionBasis(selectedPg?.cardType)
    }
  }, [paymentGateway.commissionBasedOn, selectedPg])
  useEffect(() => {
    if (selectedPg && paymentGateway.commissionBasedOn === "payment type") {
      setTransactionBasis(selectedPg?.paymentType)
    }

    if (selectedPg && paymentGateway.commissionBasedOn === "card type") {
      setTransactionBasis(selectedPg?.cardType)
    }
  }, [paymentGateway.commissionBasedOn, selectedPg])

  useEffect(() => {
    if (
      paymentGateway.paymentGateway !== undefined &&
      Array.isArray(paymentGatewayList)
    ) {
      const selectedpaymentgateWay = paymentGatewayList.filter(
        i => i.paymentGatewayId == paymentGateway.paymentGatewayId
      )[0]

      setSelectedPg(selectedpaymentgateWay)
    }
  }, [paymentGateway.paymentGateway, paymentGatewayList])

  useEffect(() => {
    if (Array.isArray(paymentGateway.customConfig)) {
      paymentGateway.customConfig.map((config, index) => {
        if (config.id === undefined) {
          config.id = uuidv4()
        }
      })
    }
  }, [paymentGateway.customConfig])
  useEffect(() => {
    if (Array.isArray(paymentGateway.commissionSettings)) {
      paymentGateway.commissionSettings.map((comm, index) => {
        if (comm.id === undefined) {
          comm.id = uuidv4()
        }
      })
    }
  }, [paymentGateway.commissionSettings])
  useEffect(() => {
    if (Array.isArray(paymentGateway?.transactionFee)) {
      paymentGateway?.transactionFee.map((comm, index) => {
        if (comm.id === undefined) {
          comm.id = uuidv4()
        }
      })
    }
  }, [paymentGateway?.transactionFee])

  function handleChange(changes) {
    handlePaymentGatewayChange(paymentGateway.id, {
      ...paymentGateway,
      ...changes,
    })
  }

  function handleCommissionChange(id, commission) {
    const newCommissions = [...paymentGateway.commissionSettings]
    const index = newCommissions.findIndex(i => i.id === id)
    newCommissions[index] = commission
    handleChange({ commissionSettings: newCommissions })
  }
  function handleTransactionChange(id, transaction) {
    // console.log(id, transaction)
    const newTransaction = [...paymentGateway?.transactionFee]
    const index = newTransaction.findIndex(i => i.id === id)
    newTransaction[index] = transaction
    handleChange({ transactionFee: newTransaction })
  }
  function handleCustomConfigChange(id, config) {
    // console.log(id, config)
    const newCustomConfigs = [...paymentGateway.customConfig]
    const index = newCustomConfigs.findIndex(i => i.id === id)
    newCustomConfigs[index] = config
    handleChange({ customConfig: newCustomConfigs })
  }

  function handleCommissionAdd() {
    const newCommission = {
      id: uuidv4(),
      basis: "",
      fixed: 0,
      percentage: 0,
    }
    handleChange({
      commissionSettings: [...paymentGateway.commissionSettings, newCommission],
    })
  }

  function handleTransactionAdd() {
    const newTransaction = {
      id: uuidv4(),
      basis: "",
      fixed: 0,
      percentage: 0,
    }
    handleChange({
      transactionFee: [...paymentGateway?.transactionFee, newTransaction],
    })
  }

  function handleCustomConfigAdd() {
    const newCustomConfig = {
      id: uuidv4(),
      key: "",
      value: "",
    }
    handleChange({
      customConfig: [...paymentGateway.customConfig, newCustomConfig],
    })
  }

  function handleCommissionDelete(id) {
    handleChange({
      commissionSettings: paymentGateway.commissionSettings.filter(
        i => i.id !== id
      ),
    })
  }
  function handleTransactionDelete(id) {
    handleChange({
      transactionFee: paymentGateway?.transactionFee.filter(i => i.id !== id),
    })
  }

  function handleCustomConfigDelete(id) {
    handleChange({
      customConfig: paymentGateway.customConfig.filter(i => i.id !== id),
    })
  }

  function handleSelectedPgId(e) {
    const index = e.target.selectedIndex
    const optionElement = e.target.childNodes[index]
    const optionElementId = optionElement.getAttribute("id")
    handleChange({
      paymentGateway: e.target.value,
      paymentGatewayId: optionElementId,
    })

    const selectedpaymentgateWay = paymentGatewayList.filter(
      i => i.paymentGatewayId == optionElementId
    )[0]
    setSelectedPg(selectedpaymentgateWay)
  }

  function handleSelectedCommissionBasedOn(value) {
    if (selectedPg) {
      if (value === "payment type") {
        setCommissionBasis(selectedPg?.paymentType)
      }

      if (value === "card type") {
        setCommissionBasis(selectedPg?.cardType)
      }
    }

    handleChange({ commissionBasedOn: value })
  }

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#e5edff",
          padding: "10px",
          marginBottom: "30px",
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="3">
              {/* TODO: pg get from db*/}
              <FormGroup>
                <label htmlFor="subject">Payment Gateway </label>
                <select
                  className="custom-select"
                  value={paymentGateway.paymentGateway}
                  name={inputPaymentGateway}
                  onChange={e => handleSelectedPgId(e)}
                  ref={register({
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  {pgList &&
                    pgList?.map((pg, index) => (
                      <option
                        key={index}
                        id={pg.paymentGatewayId}
                        value={pg.paymentGateway}
                      >
                        {pg.paymentGateway}
                      </option>
                    ))}
                </select>

                <small className="color-danger">
                  {errors[inputPaymentGateway] &&
                    errors[inputPaymentGateway].message}
                </small>
              </FormGroup>
            </Col>
            <Col lg="1">
              <FormGroup>
                <label htmlFor="subject">Status</label>
                <select
                  ref={register({ required: true })}
                  className="custom-select"
                  value={paymentGateway.isActive}
                  name={inputIsActive}
                  onChange={e =>
                    handleChange({ isActive: parseBool(e.target.value) })
                  }
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                <small className="color-danger">
                  {errors[inputIsActive] && errors[inputIsActive].message}
                </small>
              </FormGroup>
            </Col>

            {user[0]?.role === "SuperAdmin" ? (
              <Col lg="2">
                <FormGroup>
                  <label htmlFor="subject">Commission</label>
                  <select
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                    className="custom-select"
                    value={paymentGateway.commissionBasedOn}
                    name={inputCommissionBasedOn}
                    onChange={e =>
                      handleSelectedCommissionBasedOn(e.target.value)
                    }
                  >
                    <option value="" selected="selected" hidden="hidden">
                      Choose here
                    </option>
                    <option value="payment type">
                      Payment Type (credit / debit)
                    </option>
                    <option value="card type">Card Type (visa / master)</option>
                  </select>
                  <small className="color-danger">
                    {errors[inputCommissionBasedOn] &&
                      errors[inputCommissionBasedOn].message}
                  </small>
                </FormGroup>
              </Col>
            ) : null}

            <Col lg="1">
              <FormGroup>
                <label style={{ color: "#e5edff" }} htmlFor="subject">
                  Delete{" "}
                </label>

                <Button
                  onClick={e => handlePaymentGatewayDelete(paymentGateway.id)}
                  color="danger"
                  className="btn-block inner"
                  style={{ width: "100%" }}
                >
                  {" "}
                  Delete PG{" "}
                </Button>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="8">
              <b>Add Custom Configurations</b>
              <p></p>
              {paymentGateway.customConfig &&
                paymentGateway.customConfig.map((config, index) => (
                  <CustomConfig
                    index={index}
                    indexPG={props.index}
                    selectedPg={selectedPg}
                    key={index}
                    config={config}
                    handleCustomConfigChange={handleCustomConfigChange}
                    handleCustomConfigDelete={handleCustomConfigDelete}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                  />
                ))}

              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  onClick={() => handleCustomConfigAdd()}
                  color="warning"
                  className="mt-1"
                >
                  + Add Config
                </Button>
              </div>
            </Col>
          </Row>
          {paymentGateway?.transactionFee ? (
            <>
              <hr></hr>
              <b>Add Transaction Fee Settings</b>

              <Row>
                <Col lg="8">
                  {paymentGateway.transactionFee &&
                    paymentGateway.transactionFee.map((transaction, index) => (
                      <TransactionFeeSettings
                        index={index}
                        indexPG={props.index}
                        selectedPg={selectedPg}
                        transactionBasis={transactionBasis}
                        key={index}
                        transaction={transaction}
                        handleTransactionChange={handleTransactionChange}
                        handleTransactionDelete={handleTransactionDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                      />
                    ))}
                </Col>
              </Row>

              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  onClick={() => handleTransactionAdd()}
                  color="warning"
                  className="mt-1"
                >
                  + Add Transaction Fee
                </Button>
              </div>
            </>
          ) : null}

          {user[0]?.role === "SuperAdmin" ? (
            <>
              <hr></hr>
              <b>Add Commission Settings</b>
              <p>For internal purposes only.</p>

              <Row>
                {paymentGateway.commissionSettings &&
                  paymentGateway.commissionSettings.map((commission, index) => (
                    <CommissionSettings
                      index={index}
                      indexPG={props.index}
                      selectedPg={selectedPg}
                      commissionBasis={commissionBasis}
                      key={index}
                      commission={commission}
                      handleCommissionChange={handleCommissionChange}
                      handleCommissionDelete={handleCommissionDelete}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      register={register}
                      errors={errors}
                    />
                  ))}
              </Row>

              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  onClick={() => handleCommissionAdd()}
                  color="warning"
                  className="mt-1"
                >
                  + Add Payment Method & Commission
                </Button>
              </div>
            </>
          ) : null}
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateways)
