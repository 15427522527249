import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Row,
  Col,
  Form,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import WhatsAppLogo from "../../assets/images/whatsapp.png"
import classnames from "classnames"
import { Link } from "react-router-dom"
import ExistingCustomerData from "./ExistingCustomerData"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import SelectPaymentTypes from "../../components/PaymentTypesSelect/index"
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    height: "59px",
  }),
}
const PaymentRequestExpressModal = props => {
  const {
    isOpen,
    toggle,
    prDetails,
    commissionSettingsPaymentTypes,
    handlePaymentRequestChange,
    customers,
    onChange,
    handlePRCreate,
    loading,
    countryCodes,
  } = props
  const multi = {
    filtered: [],
    select2: undefined,
  }
  const animatedComponents = makeAnimated()
  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [activeTab, setactiveTab] = useState("1")
  const [customer, setCustomer] = useState(false)
  const [existingCustomer, setExistingCustomer] = useState(false)
  const [areaCode, setAreaCode] = useState("+974")
  const [mobile, setMobile] = useState("")
  const [paymentType, setPaymentType] = useState([])
  const [selectCustomer, setSelectCustomer] = useState({})
  const [emailType, setEmailType] = useState("")
  const [search, setSearch] = useState("")
  const [cusList, setCusList] = useState(customers)
  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi)
  const [paymentTypesErrror, setPaymentTypesError] = useState(false)
  const [optionsPaymentTypes, setOpTypes] = useState([])
  const [valueStorage, setValueStorage] = useState([
    {
      value: "<SELECT_ALL>",
      label: "all",
    },
  ])
  // let optionsPaymentTypes = paymentType?.map((pt, i) => {
  //   return { value: pt, label: pt }
  // })

  ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let areaCodeMobile = areaCode + "-" + mobile
    handleChangeCustomerDetails({ Mobile: areaCodeMobile })
    setAreaCode(areaCode)
    setMobile(mobile)
  }, [areaCode, mobile])

  useEffect(() => {
    //console.log(prDetails.CustomerDetails)
    if (prDetails.CustomerDetails.Mobile === "") {
      setMobile("")
    }
  }, [prDetails.CustomerDetails])

  useEffect(() => {
    if (
      commissionSettingsPaymentTypes !== undefined &&
      Array.isArray(commissionSettingsPaymentTypes)
    ) {
      setPaymentType(commissionSettingsPaymentTypes)
    }
  }, [commissionSettingsPaymentTypes])
  useEffect(() => {
    if (Array.isArray(paymentType)) {
      //console.log(optionsPaymentTypes)
      setOpTypes(
        paymentType?.map((pt, i) => {
          return { value: pt, label: pt }
        })
      )
      onFilteredChangeCustom(paymentType, "entry")
    }
  }, [paymentType])
  useEffect(() => {
    //console.log(optionsPaymentTypes)
    if (Array.isArray(optionsPaymentTypes)) {
      setValueStorage(optionsPaymentTypes)
    }
  }, [optionsPaymentTypes])

  useEffect(() => {
    //console.log(customers)
  }, [customers])

  useEffect(() => {
    //console.log(selectCustomer)
    if (selectCustomer !== undefined) {
      onChange(selectCustomer)
      handleChangeCustomerDetails({
        Name: selectCustomer.name,
        Mobile: selectCustomer.mobile,
        Email: selectCustomer.email,
        Type: "Recurring",
      })
      if (selectCustomer.mobile !== undefined) {
        let splitMobile = selectCustomer?.mobile?.split("-")
        //console.log(splitMobile)
        setAreaCode(splitMobile[0])
        setMobile(splitMobile[1])
      }
    }
  }, [selectCustomer])

  useEffect(() => {
    //console.log(search)
    if (search !== "") {
      setCusList(
        customers.filter(
          item =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase()) ||
            item.mobile.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else {
      setCusList(customers)
    }
    if (search === "") {
      setCusList(customers)
    }
  }, [search])
  useEffect(() => {
    //console.log(cusList)
  }, [cusList])
  useEffect(() => {
    //console.log(selectedMultiSelect.filtered[0]?.value)
    if (Array.isArray(selectedMultiSelect.filtered[0]?.value)) {
      handleChangePrSettingsDetails({
        PaymentType: selectedMultiSelect.filtered[0]?.value.toString(),
      })
    }
    //console.log(selectedMultiSelect.filtered[0]?.value)
    if (selectedMultiSelect.filtered[0]?.value === "") {
      handleChangePrSettingsDetails({
        PaymentType: "",
      })
    }
  }, [selectedMultiSelect])

  useEffect(() => {
    //console.log(paymentTypesErrror)
  }, [paymentTypesErrror])
  useEffect(() => {
    //console.log(valueStorage)
    if (Array.isArray(valueStorage)) {
      const array = new Array()
      valueStorage.map(value => {
        array.push(value.value)
      })
      onFilteredChangeCustom(array, "entry")
    }
  }, [valueStorage])
  ////////////////////////////////////////////////////////////////////////////
  function onFilteredChangeCustom(value, accessor) {
    let filtered = selectedMultiSelect.filtered
    let insertNewFilter = 1

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1)
          else filter["value"] = value

          insertNewFilter = 0
        }
      })
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value })
    }

    setselectedMultiSelect({ filtered: filtered })
  }
  function handleChange(changes) {
    //console.log(changes)
    handlePaymentRequestChange({ ...prDetails, ...changes })
  }
  function handleChangePRAmount(changes) {
    handleChange({ AmountDetails: { ...prDetails.AmountDetails, ...changes } })
  }
  function handleEmail(value) {
    handlePRCreate(value)
  }

  function handleChangeCustomerDetails(changes) {
    //console.log(changes)
    handleChange({
      CustomerDetails: { ...prDetails.CustomerDetails, ...changes },
    })
  }

  function handleChangePrSettingsDetails(changes) {
    handleChange({
      PaymentRequestSettings: {
        ...prDetails.PaymentRequestSettings,
        ...changes,
      },
    })
  }

  const onSubmit = () => {
    if (
      selectedMultiSelect.filtered[0]?.value.length !== 0 &&
      selectedMultiSelect.filtered[0]?.value.length !== undefined
    ) {
      setPaymentTypesError(false)
      handleCheckNoErrors()
    } else {
      setPaymentTypesError(true)
    }
  }

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      handleEmail(emailType)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
    if (tab == "2") {
      setExistingCustomer(true)
    }
    if (tab == "1") {
      setExistingCustomer(false)
    }
  }

  const customerCollapse = () => {
    setCustomer(!customer)
  }

  // const existingCustomerCollapse = () => {
  //   setExistingCustomer(!existingCustomer)
  // }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      size="md"
      onHide={true}
      backdrop="static"
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Send Express Payment Request</ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)} id="my-form">
          {loading ? (
            <Row className="spinner-custom mx-auto my-3 ">
              <LoadingSpinnerThreeDots />
            </Row>
          ) : (
            <>
              <ModalBody>
                {/* <PaymentRequestExpressForm /> */}

                <Row>
                  <Col md="6">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "100%" }}
                    >
                      <select
                        className="form-select form-control bg-color-amount border-none  input-text-size-currency bold"
                        value={prDetails.AmountDetails.Currency}
                        name="Currency"
                        onChange={e =>
                          handleChangePRAmount({ Currency: e.target.value })
                        }
                        ref={register({ required: true })}
                      >
                        <option value="QAR" defaultValue>
                          QAR
                        </option>
                      </select>
                      <label htmlFor="floatingnameInput">Currency</label>
                      {errors.Currency &&
                        errors.Currency.type === "required" && (
                          <small className="color-danger">
                            This is required
                          </small>
                        )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-floating mb-3 ">
                      <input
                        type="number"
                        className="form-control bg-color-amount input-text-size-amount bold border-none"
                        id="amount"
                        min="0"
                        step=".01"
                        // placeholder="Enter Amount"
                        value={prDetails.AmountDetails.InvoiceAmount}
                        onChange={e =>
                          handleChangePRAmount({
                            InvoiceAmount: parseFloat(e.target.value) || "",
                          })
                        }
                        name="Amount"
                        ref={register({
                          required: {
                            value: true,
                            message: "This is required",
                          },
                          min: {
                            value: 1,
                            message: "Not a valid amount",
                          },
                        })}
                      />
                      <label htmlFor="floatingnameInput">Amount Due</label>
                      {errors.Amount && errors.Amount.type === "required" && (
                        <small className="color-danger">This is required</small>
                      )}
                      {errors.Amount && errors.Amount.type === "min" && (
                        <small className="color-danger">
                          {errors.Amount.message}
                        </small>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="form-floating mb-3 ">
                      {paymentType.length <= 1 ? (
                        <Select
                          styles={customStyles}
                          name="multiSelect"
                          id="multiSelect"
                          // defaultValue={{
                          //   value: paymentTypes.toString(),
                          //   label: "all",
                          // }}
                          //value={selectedMultiSelect.select2}
                          value={valueStorage}
                          isMulti={true}
                          touchUi={false}
                          onChange={setValueStorage}
                          // onChange={entry => {
                          //   if (entry) {
                          //     setselectedMultiSelect({ select2: entry })
                          //     onFilteredChangeCustom(
                          //       entry.map(o => {
                          //         return o.value
                          //       }),
                          //       "entry"
                          //     )
                          //   }
                          //   if (entry === null) {
                          //     setselectedMultiSelect({ select2: entry })
                          //     onFilteredChangeCustom("", "entry")
                          //   }
                          // }}
                          // placeholder="Select Payment Type ..."
                          options={optionsPaymentTypes}
                          //isOptionsSelected={isOptionsSelected}
                          classNamePrefix="multiSelect"
                          closeMenuOnSelect={false}
                          //hideSelectedOptions={false}
                          components={animatedComponents}
                        />
                      ) : (
                        <SelectPaymentTypes
                          options={optionsPaymentTypes}
                          value={valueStorage}
                          onChange={setValueStorage}
                        />
                      )}

                      {paymentTypesErrror === true ? (
                        <small className="color-danger">This is required</small>
                      ) : null}
                      {paymentType.length === 0 ? (
                        <small className="color-danger">
                          No active payment gateway.
                        </small>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <div className="form-floating mb-3">
                      <select
                        value={prDetails.PaymentRequestSettings.Language}
                        className="form-select form-control"
                        onChange={e =>
                          handleChangePrSettingsDetails({
                            Language: e.target.value,
                          })
                        }
                        ref={register({ required: true })}
                        name="Language"
                      >
                        <option value="" hidden="hidden">
                          Choose here
                        </option>
                        <option value="En">English</option>
                        <option value="Ar">Arabic</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">Language</label>
                      {errors.Language &&
                        errors.Language.type === "required" && (
                          <small className="color-danger">
                            This is required
                          </small>
                        )}
                    </div>
                  </Col>
                  <Col lg="8">
                    <div
                      className="form-floating mb-3 mr-4 "
                      style={{ width: "70%", float: "left" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        // placeholder="Remarks"
                        value={prDetails.Remarks}
                        onChange={e =>
                          handleChange({ Remarks: e.target.value })
                        }
                      />
                      <label htmlFor="floatingnameInput">Remarks</label>
                    </div>

                    <div>
                      <Link
                        to="#"
                        onClick={customerCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="fas fa-user-plus fa-3x"
                          style={{ color: "green" }}
                        />
                      </Link>
                      {errors.Name && errors.Name.type === "required" && (
                        <div>
                          <small className="color-danger">
                            This is Required
                          </small>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Collapse isOpen={customer}>
                  <div style={{ backgroundColor: "#F5F5F5" }}>
                    {/* NAV BAR */}
                    <Row>
                      <Col>
                        <Nav
                          pills
                          justified
                          style={{ backgroundColor: "#d7e5f0" }}
                        >
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggleTab("1")
                              }}
                            >
                              <i className="bx bx-chat font-size-20 d-sm-none" />
                              <span className="d-none d-sm-block">
                                New Customer
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggleTab("2")
                              }}
                            >
                              <i className="bx bx-group font-size-20 d-sm-none" />
                              <span className="d-none d-sm-block">
                                Existing Customer
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>

                    {/* EXISTING CUSTOMER */}
                    <Collapse isOpen={existingCustomer}>
                      <Row>
                        <Col>
                          <input
                            id="search"
                            type="text"
                            className="form-control mx-auto my-2"
                            // placeholder="Search..."
                            onChange={event => setSearch(event.target.value)}
                          />
                          {Array.isArray(cusList) &&
                          Array.isArray(customers) ? (
                            <ExistingCustomerData
                              data={
                                cusList.length !== 0
                                  ? cusList
                                  : search !== ""
                                  ? cusList
                                  : customers
                              }
                              rowsPerPage={4}
                              onChange={value => setSelectCustomer(value)}
                            />
                          ) : (
                            <Label className="ml-2">No Customers</Label>
                          )}
                        </Col>
                      </Row>
                      <hr></hr>
                    </Collapse>
                    {/* CUSTOMER INPUT DETAILS */}

                    {activeTab === "1" ? (
                      <>
                        <Row className="mt-3">
                          <Col lg="12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control color-text"
                                value={prDetails.CustomerDetails.Name}
                                onChange={e => {
                                  const re = /^[a-zA-Z\s]*$/

                                  if (re.test(e.target.value) === true) {
                                    handleChangeCustomerDetails({
                                      Name: e.target.value,
                                    })
                                  }
                                }}
                                id="name"
                                name="Name"
                                // placeholder="Enter Name"
                                ref={register({ required: true })}
                                // readOnly
                              />
                              <label htmlFor="floatingnameInput"> Name</label>
                              {errors.Name && errors.Name.type === "required" && (
                                <>
                                  <small className="color-danger">
                                    This is required
                                  </small>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control  color-text"
                                id="email"
                                name="Email"
                                // placeholder="Enter Email"
                                value={prDetails.CustomerDetails.Email}
                                // readOnly
                                onChange={e =>
                                  handleChangeCustomerDetails({
                                    Email: e.target.value,
                                  })
                                }
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "This is required",
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
                                    message: "invalid email address",
                                  },
                                })}
                              />
                              <label htmlFor="floatingnameInput"> Email</label>
                              {errors.Email &&
                                errors.Email.type === "required" && (
                                  <small className="color-danger">
                                    {errors.Email.message}
                                  </small>
                                )}
                              {errors.Email &&
                                errors.Email.type === "pattern" && (
                                  <small className="color-danger">
                                    {errors.Email.message}
                                  </small>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div
                              className="form-floating mb-3"
                              style={{ width: "100%" }}
                            >
                              <select
                                value={areaCode}
                                className="form-select form-control"
                                onChange={e => setAreaCode(e.target.value)}
                                name="AreaCode"
                              >
                                <option value=" +974" hidden="hidden">
                                  +974
                                </option>
                                {countryCodes?.map((cc, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={"+" + cc.countryCode}
                                    >
                                      +{cc.countryCode}
                                    </option>
                                  )
                                })}
                              </select>
                              <label htmlFor="floatingSelectGrid">Area</label>
                            </div>
                          </Col>

                          <Col lg="8">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control color-text"
                                id="mobile"
                                name="mobile"
                                // readOnly
                                // placeholder="Enter Mobile"
                                value={mobile}
                                onChange={e => {
                                  const me = /^[0-9\s]*$/
                                  if (me.test(e.target.value)) {
                                    setMobile(
                                      e.target.value.replaceAll(" ", "")
                                    )
                                  }
                                }}
                                ref={register({ required: true })}
                              />
                              <label htmlFor="floatingnameInput">Mobile</label>
                              {errors.mobile &&
                                errors.mobile.type === "required" && (
                                  <small className="color-danger">
                                    This is required
                                  </small>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row className="mt-3">
                          <Col lg="12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                readOnly
                                className="form-control color-text bg-color-disable"
                                value={prDetails.CustomerDetails.Name}
                                onChange={e =>
                                  handleChangeCustomerDetails({
                                    Name: e.target.value,
                                  })
                                }
                                id="name"
                                name="Name"
                                // placeholder="Enter Name"
                                ref={register({ required: true })}
                                // readOnly
                              />
                              <label htmlFor="floatingnameInput"> Name</label>
                              {errors.Name && errors.Name.type === "required" && (
                                <>
                                  <small className="color-danger">
                                    This is required
                                  </small>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control  color-text bg-color-disable"
                                id="email"
                                name="Email"
                                // placeholder="Enter Email"
                                value={prDetails.CustomerDetails.Email}
                                // readOnly
                                onChange={e =>
                                  handleChangeCustomerDetails({
                                    Email: e.target.value,
                                  })
                                }
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "This is required",
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
                                    message: "invalid email address",
                                  },
                                })}
                              />
                              <label htmlFor="floatingnameInput"> Email</label>
                              {errors.Email &&
                                errors.Email.type === "required" && (
                                  <small className="color-danger">
                                    {errors.Email.message}
                                  </small>
                                )}
                              {errors.Email &&
                                errors.Email.type === "pattern" && (
                                  <small className="color-danger">
                                    {errors.Email.message}
                                  </small>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div
                              className="form-floating mb-3"
                              style={{ width: "100%" }}
                            >
                              <input
                                type="string"
                                readOnly
                                className="form-control color-text bg-color-disable"
                                id="AreaCode"
                                name="AreaCode"
                                // readOnly
                                // placeholder="Enter Mobile"
                                value={areaCode}
                                onChange={e => setAreaCode(e.target.value)}
                                ref={register({ required: true })}
                              />
                              {/* <select
                      value={areaCode}
                      className="form-select form-control bg-color-disable"
                      onChange={e => setAreaCode(e.target.value)}
                      name="AreaCode"
                    >
                      <option value=" +974" hidden="hidden">
                        +974
                      </option>
                      <option value="+9731">+9731</option>
                      <option value="+63">+63</option>
                    </select> */}

                              <label htmlFor="floatingSelectGrid">Area</label>
                            </div>
                          </Col>

                          <Col lg="8">
                            <div className="form-floating mb-3">
                              <input
                                type="number"
                                readOnly
                                className="form-control color-text bg-color-disable"
                                id="mobile"
                                name="mobile"
                                // readOnly
                                // placeholder="Enter Mobile"
                                value={mobile}
                                onChange={e => setMobile(e.target.value)}
                                ref={register({ required: true })}
                              />
                              <label htmlFor="floatingnameInput">Mobile</label>
                              {errors.mobile &&
                                errors.mobile.type === "required" && (
                                  <small className="color-danger">
                                    This is required
                                  </small>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Collapse>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col>
                    <div className="d-flex flex-row">
                      <Button
                        data-tip="<div><small>Send invoice to customer Whatsapp.</small></div> "
                        data-html={true}
                        type="submit"
                        className="color-whatsapp  border-none mr-3"
                        onClick={() => setEmailType("whatsapp")}
                      >
                        <img src={WhatsAppLogo} className="whatsapplogo mr-2" />
                        WhatsApp
                      </Button>

                      <Button
                        data-tip="<div><small>Sends out request</small> </div> <div><small>to your email without</small></div>  <div><small> notifying the customer.</small></div> "
                        data-html={true}
                        type="submit"
                        color="primary"
                        className="border-none mr-3"
                        onClick={() => setEmailType("user")}
                      >
                        <i className="far fa-envelope mr-2" />
                        MY EMAIL
                      </Button>
                      <Button
                        data-tip="<div><small>Sends out request</small> </div> <div><small>to customer including you</small></div>  <div><small> and the registered</small></div>  <div><small> notification emails.</small></div> "
                        data-html={true}
                        type="submit"
                        color="success"
                        className="border-none mr-3"
                        onClick={() => setEmailType("all")}
                      >
                        <i className="far fa-envelope-open mr-2" /> TO CUSTOMER
                      </Button>
                    </div>
                  </Col>
                </Row>
              </ModalFooter>
            </>
          )}
        </Form>
      </div>
    </Modal>
  )
}

PaymentRequestExpressModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default PaymentRequestExpressModal
