import React, { useEffect, useState, useContext } from "react"
import { connect } from "react-redux"
import { Col, Form, Row, Button } from "reactstrap"
import { OfficeIds } from "../OfficeIds"
import { v4 as uuidv4 } from "uuid"
import { PaymentGateways } from "../PaymentGateways"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { MerchantCreateContext } from "../index"
import paymentGateway from "store/paymentgateway/reducer"
import AuthUser from "components/AuthUser/AuthUser"
export const ThirdPaymentGateway = props => {
  const {
    handleMerchantChange,
    merchantDetails,
    PaymentGatewayList,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const { settingThirdStepError } = useContext(MerchantCreateContext)
  const user = useState(AuthUser())
  useEffect(() => {
    if (Array.isArray(merchantDetails.paymentGatewayEnrollments)) {
      merchantDetails.paymentGatewayEnrollments.map((pg, index) => {
        if (pg.id === undefined) {
          pg.id = uuidv4()
        }
      })
    }
  }, [merchantDetails.paymentGatewayEnrollments])
  useEffect(() => {
    if (Array.isArray(merchantDetails.officeIds)) {
      merchantDetails.officeIds.map((oid, index) => {
        if (oid.id === undefined) {
          oid.id = uuidv4()
        }
      })
    }
  }, [merchantDetails.officeIds])

  function handleChange(changes) {
    handleMerchantChange({ ...merchantDetails, ...changes })
  }

  function handleOfficeIdChange(id, officeId) {
    const newOfficeIds = [...merchantDetails.officeIds]
    const index = newOfficeIds.findIndex(i => i.id === id)
    newOfficeIds[index] = officeId
    handleChange({ officeIds: newOfficeIds })
  }

  function handleOfficeIdAdd() {
    const newOfficeId = {
      id: uuidv4(),
      officeId: "",
      date: new Date().toISOString(),
    }
    handleChange({ officeIds: [...merchantDetails.officeIds, newOfficeId] })
  }

  function handleOfficeIdDelete(id) {
    handleChange({
      officeIds: merchantDetails.officeIds.filter(i => i.id !== id),
    })
  }

  function handlePaymentGatewayChange(id, paymentGateway) {
    const newPaymentGateways = [...merchantDetails.paymentGatewayEnrollments]
    const index = newPaymentGateways.findIndex(i => i.id === id)
    newPaymentGateways[index] = paymentGateway
    handleChange({ paymentGatewayEnrollments: newPaymentGateways })
  }

  function handlePaymentGatewayAdd() {
    const newPaymentGateway = {
      id: uuidv4(),
      paymentGateway: "",
      isActive: false,
      paymentGatewayId: "",
      commissionSettings: [
        {
          id: uuidv4(),
          basis: "",
          fixed: 0,
          percentage: 0,
        },
      ],
      customConfig: [
        {
          id: uuidv4(),
          key: "",
          value: "",
        },
      ],
    }
    handleChange({
      paymentGatewayEnrollments: [
        ...merchantDetails.paymentGatewayEnrollments,
        newPaymentGateway,
      ],
    })
  }

  function handlePaymentGatewayDelete(id) {
    handleChange({
      paymentGatewayEnrollments:
        merchantDetails.paymentGatewayEnrollments.filter(i => i.id !== id),
    })
  }

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="6">
            {merchantDetails.type && merchantDetails.type === "Travel Agency" && (
              <>
                <b>Add OfficeIds</b>
                <p></p>
                {merchantDetails.officeIds &&
                  merchantDetails.officeIds.map((officeId, index) => (
                    <OfficeIds
                      index={index}
                      key={index}
                      officeId={officeId}
                      handleOfficeIdChange={handleOfficeIdChange}
                      handleOfficeIdDelete={handleOfficeIdDelete}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      register={register}
                      errors={errors}
                    />
                  ))}

                <Button
                  onClick={() => handleOfficeIdAdd()}
                  color="success"
                  className="mt-1"
                >
                  Add New OfficeId
                </Button>
              </>
            )}
          </Col>
        </Row>

        <hr></hr>
        <b>Add Default Payment Gateway</b>
        <p></p>

        {merchantDetails.paymentGatewayEnrollments &&
          merchantDetails.paymentGatewayEnrollments?.map(
            (paymentGateway, index) => (
              <PaymentGateways
                index={index}
                paymentGatewayList={PaymentGatewayList}
                key={index}
                paymentGateway={paymentGateway}
                handlePaymentGatewayChange={handlePaymentGatewayChange}
                handlePaymentGatewayDelete={handlePaymentGatewayDelete}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                errors={errors}
              />
            )
          )}

        <Button
          onClick={() => handlePaymentGatewayAdd()}
          color="success"
          className="mt-1"
        >
          Add Payment Gateway
        </Button>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 5 ? "next" : "next"}>
            {activeTabProgress === 5 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button type="submit" color="primary" id="my-form_submit_3">
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>
      </Form>
    </div>
  )
}

ThirdPaymentGateway.propTypes = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPaymentGateway)
