import React, { useContext, useEffect, useState } from "react"
import { Col, Row, Card, CardBody, Table, Label } from "reactstrap"
import { ValuesContext } from "./index"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { IsEmpty } from "react-lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import { connect } from "react-redux"
import PayReqCol from "./PaymentRequestColumns"
import ManAvatar from "../../assets/images/images/man.jpg"
import WomanAvatar from "../../assets/images/images/woman.jpg"
import UserProfileCard from "./UserProfileCard"
import TravelAgencyLogoCard from "./TravelAgencyLogoCard"
import moment from "moment"
import { map } from "lodash"
import SimpleBar from "simplebar-react"

function ProfileLayout(props) {
  const data = useContext(ValuesContext)

  const [prCount, setPRCount] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [prList, setPRList] = useState({})
  const [merchantDetail, setMerchantDetail] = useState({})
  const [userLogs, setUserLogs] = useState({})

  let searchText = ""
  const pageOptions = {
    sizePerPage: 5,
    totalSize: prList.length,
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChange()
    },
  }
  let userAvatar
  const { SearchBar } = Search
  const [userLoading, setUserLoading] = useState(props.userLoading)
  const [prLoading, setPRLoading] = useState(props.prLoading)

  useEffect(() => {
    setMerchantDetail(data.merchantDetail)
  }, [data.merchantDetail])

  useEffect(() => {
    setPRLoading(true)
    if (Array.isArray(data.prList)) {
      setPRCount(handleSuccessSent())
      setRevenue(handleRevenue())
      setPRList(data.prList)
    }
  }, [data.prList, props.prLoading])

  useEffect(() => {}, [prLoading])

  useEffect(() => {
    setUserLoading(true)
    if (Array.isArray(data.logs)) {
      setUserLogs(data.logs)
    }
  }, [data.logs])

  useEffect(() => {
    setUserLoading(false)
  }, [userLogs])

  useEffect(() => {
    setPRLoading(false)
  }, [prList])

  switch (data.user && data.user.title) {
    case "Ms":
      userAvatar = WomanAvatar
      break
    case "Mr":
      userAvatar = ManAvatar
      break
    default:
      userAvatar = ManAvatar
      break
  }

  const handleRevenue = () => {
    let amount = 0
    if (Array.isArray(data.prList)) {
      data.prList.map((pr, i) => {
        if (
          pr.paymentStatus === "Success" ||
          pr.paymentStatus === "Refund Pending" ||
          pr.paymentStatus === "Refund Rejected"
        ) {
          amount = amount + pr.amountDetails.totalAmount
        }
      })
    }
    return amount.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })
  }

  const handleSuccessSent = () => {
    let count = 0
    if (Array.isArray(data.prList)) {
      data.prList.map((pr, i) => {
        if (pr.paymentStatus === "Success") {
          count++
        }
      })
    }
    return count
  }

  const handleTableChange = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setPRList(
        prList.filter(prlist =>
          Object.keys(prlist).some(key =>
            prlist[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const handleOpenOverviewPrId = paymentRequestId => {
    const pr = prList.filter(x => x.paymentRequestId == paymentRequestId)
    const id = pr.map(function (p) {
      return p._id
    })
    const link = "/paymentrequest/overview/" + id
    window.open(link, "_blank")
  }

  return (
    <Row>
      <Col>
        {/* <TravelAgencyLogoCard /> */}
        <UserProfileCard
          user={data.user}
          prCount={prCount}
          revenue={revenue}
          merchantDetail={merchantDetail}
        />

        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label> Personal Information</Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <tbody>
                    <tr>
                      <td>Title :</td>
                      <td>
                        {data.user && data.user.title === ""
                          ? "N/A"
                          : data.user && data.user.title}
                      </td>
                    </tr>
                    <tr>
                      <td>Name :</td>
                      <td>
                        {data.user && data.user.firstName === ""
                          ? "N/A"
                          : data.user && data.user.firstName}{" "}
                        {data.user && data.user.lastName === ""
                          ? "N/A"
                          : data.user && data.user.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Email :</td>
                      <td>
                        {data.user && data.user.email === ""
                          ? "N/A"
                          : data.user && data.user.email}
                      </td>
                    </tr>
                    <tr>
                      <td>Mobile :</td>
                      <td>
                        {data.user && data.user.mobile === ""
                          ? "N/A"
                          : data.user && data.user.mobile}
                      </td>
                    </tr>
                    <tr>
                      <td>Department :</td>
                      <td>
                        {data.user && data.user.department === ""
                          ? "N/A"
                          : data.user && data.user.department}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <Label> Payment Requests</Label>
            {prLoading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              <IsEmpty
                value={prList}
                yes={() => (
                  <Row>
                    <Col>No list</Col>
                  </Row>
                )}
                no={() => (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={prList || data.prList || []}
                        columns={PayReqCol(handleOpenOverviewPrId)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2  mx-2">
                              <Col sm="4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="width-search"
                                      placeholder="Search"
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    hover={true}
                                    striped={false}
                                    classes={
                                      "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                    }
                                    //rowEvents={rowEvents}
                                    keyField="_id"
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange(
                                      searchText
                                    )}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}
              />
            )}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Label>Logs</Label>
            {userLoading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              <SimpleBar style={{ maxHeight: "400px" }}>
                <ul className="verti-timeline list-unstyled">
                  {map(userLogs, (log, index) => (
                    <li key={index} className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="media">
                        <div className="mr-3">
                          <div className="tooltip_custom">
                            <small>{moment(log.date).format("DD MMM")} </small>
                            <span className="tooltiptext">
                              {moment(log.date).format(
                                "DD MMM YYYY, h:mm:ss a"
                              )}
                            </span>
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2" />
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <b>{log.action}</b> <br></br>
                            <small>
                              <i className="bx bx-time" />{" "}
                              {moment(log.date).format("h:mm:ss a")} &nbsp;
                              &nbsp; by: {log.user}{" "}
                            </small>{" "}
                            <br></br>
                            {log.email && (
                              <>
                                {log.email?.isSuccessful === true ? (
                                  <small className="list-inline-item text-muted mb-1">
                                    <b style={{ color: "#34C38F" }}>
                                      Successfully Notified:{" "}
                                    </b>
                                  </small>
                                ) : (
                                  <small className="list-inline-item text-muted mb-1">
                                    <b style={{ color: "#E5006B" }}>
                                      Email Not Sent:{" "}
                                    </b>
                                  </small>
                                )}

                                <small>
                                  TO: {log.email?.to} <br></br>CC:{" "}
                                  {log.email?.cc?.join(" ")}
                                </small>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </SimpleBar>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    prLoading: state.paymentRequests.loading,
    userLoading: state.Users.loading,
  }
}

export default connect(mapStateToProps)(ProfileLayout)
