import React, { useEffect } from "react"
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Button,
  InputGroup,
  Table,
} from "reactstrap"
import SimpleBar from "simplebar-react"

const ThirdSummary = props => {
  const {
    toggleTabProgress,
    activeTabProgress,
    loadSubmitNowButton,
    prDetails,
    itemDetails,
  } = props
  useEffect(() => {
    // console.lKog(prDetails)
  }, [prDetails])
  return (
    <>
      <Row>
        <Col lg="12">
          <div
            style={{
              backgroundColor: "#E1EFFB",
              color: "#707070",
              padding: "30px",
            }}
          >
            <div className="d-flex justify-content-center">
              <h4 className="display-4">
                {parseFloat(prDetails.AmountDetails.InvoiceAmount).toFixed(2)}{" "}
                {prDetails.AmountDetails.Currency}
              </h4>
            </div>
            <div className="d-flex justify-content-center">
              <h4 className="display-6">
                Amount due for <b>{prDetails.CustomerDetails.Name}</b>{" "}
              </h4>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ textAlign: "left", marginTop: "10px" }}>
        <Row>
          <Col lg="4">
            <Row>
              <Col>
                <i className="bx bx-user text-success mr-1 fa-3x mb-3" />
                <b style={{ color: "gray", fontSize: "1rem" }}>
                  CUSTOMER DETAILS
                </b>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Name</b>
                </p>
              </Col>
              <Col>
                <p>{prDetails.CustomerDetails.Name}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Email</b>
                </p>
              </Col>
              <Col>
                <p>{prDetails.CustomerDetails.Email}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Mobile</b>
                </p>
              </Col>
              <Col>
                <p>{prDetails.CustomerDetails.Mobile}</p>
              </Col>
            </Row>
          </Col>

          <Col lg="4">
            <Row>
              <Col>
                <i className="bx bx-detail text-success mr-1 fa-3x mb-3" />
                <b style={{ color: "gray", fontSize: "1rem" }}>MORE INFO</b>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Reference</b>
                </p>
              </Col>
              <Col>
                <p>
                  {prDetails.TransactionReference
                    ? prDetails.TransactionReference
                    : "N/A"}
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Payment Type</b>
                </p>
              </Col>
              <Col>
                <p>
                  {prDetails.PaymentRequestSettings.PaymentType.replaceAll(
                    ",",
                    ", "
                  )}
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Language </b>
                </p>
              </Col>
              <Col>
                <p>{prDetails.PaymentRequestSettings.Language}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b> Email Subject </b>
                </p>
              </Col>
              <Col>
                <p>{prDetails.EmailSettings.SubjectLine}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b> Remarks </b>
                </p>
              </Col>
              <Col>
                <p>{prDetails.Remarks ? prDetails.Remarks : "N/A"}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <b> Attachment </b>
                </p>
              </Col>
              <Col>
                <p>
                  {prDetails?.EmailSettings?.Attachment === undefined
                    ? "N/A"
                    : prDetails.EmailSettings.Attachment.length === 0
                    ? "N/A"
                    : prDetails.EmailSettings.Attachment?.map((x, i) => {
                        return (
                          <Row key={i} className="mx-auto">
                            <label className="color-text " key={i}>
                              {x.substring(14)}
                            </label>
                          </Row>
                        )
                      })}
                </p>
              </Col>
            </Row>
          </Col>

          {itemDetails && itemDetails?.Items[0]?.Qty > 0 && (
            <Col lg="4">
              <Row>
                <Col>
                  <i className="bx bx-message-square-edit text-success mr-1 fa-3x" />
                  <b style={{ color: "gray", fontSize: "1rem" }}>ITEMS</b>
                </Col>
              </Row>
              <SimpleBar>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Qty</b>
                      </td>
                      <td>
                        <b>Product</b>
                      </td>
                      <td>
                        <b>Amount</b>
                      </td>
                    </tr>

                    {itemDetails.Items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.Qty}</td>
                        <td>{item.ItemName}</td>
                        <td>{item.Amount}</td>

                        {/* <td>{pg.CommissionSettings}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SimpleBar>
            </Col>
          )}
        </Row>
      </div>
      <ul className="pager wizard twitter-bs-wizard-pager-link">
        <li
          className={activeTabProgress === 1 ? "previous disabled" : "previous"}
        >
          <Button
            color="warning"
            onClick={() => {
              toggleTabProgress(activeTabProgress - 1)
            }}
          >
            {" "}
            Previous{" "}
          </Button>
        </li>
        <li className={activeTabProgress === 3 ? "next" : "next"}>
          {activeTabProgress === 3 ? (
            <>{loadSubmitNowButton()}</>
          ) : (
            <>
              <Button type="submit" color="primary" id="my-form_submit_2">
                {" "}
                Next{" "}
              </Button>
            </>
          )}
        </li>
      </ul>
    </>
  )
}

export default ThirdSummary
