import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap"
import { isMobile } from "react-device-detect"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
// import visalogo from "../../../public/Visa_Inc.-Logo.wine.svg"

function CardTypesCheckBox(props) {
  const { paymentRequestDetails, setSelectCard, paymentCards } = props
  const [paymentTypes, setPaymentTypes] = useState([])
  const [selected, setSelected] = useState()
  const [cardArrays, setCardArrays] = useState()
  //-------------------------------------------------------------------------
  useEffect(() => {
    //console.log(paymentCards)
    let cards = paymentCards.split(",").map((c, i) => {
      let pt = paymentRequestDetails.find(p => {
        return p.basis === c
      })
      if (pt) {
        return pt
      } else {
        return {
          basis: c,
          fixed: 0,
          percentage: 0,
        }
      }
    })
    setCardArrays(cards)
  }, [paymentRequestDetails])
  useEffect(() => {
    //console.log("cards", cardArrays)
    if (cardArrays) {
      //console.log(isSafariBrowser())
      let arr = cardArrays
      if (!isSafariBrowser()) {
        arr = cardArrays.filter(c => c.basis !== "applepay")
      }
      //console.log(arr)
      setSelectCard(arr[0])
      setPaymentTypes(arr)
    }
  }, [cardArrays])
  useEffect(() => {
    // console.log(paymentTypes)
  }, [paymentTypes])
  useEffect(() => {
    // console.log(selected)
    if (selected) {
      setSelectCard(cardArrays.find(pt => pt?.basis === selected))
    }
  }, [selected])

  //-------------------------------------------------------------------------
  function isSafariBrowser() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    return isSafari
  }

  return (
    <React.Fragment>
      <Row>
        <Col></Col>
        <Col md={8} className="">
          <Row className="mt-2">
            <Col className="item-left">
              <small className="color-danger">
                {props.t("Select payment method")}
              </small>
            </Col>
          </Row>
          <Row className="my-1">
            <Col>
              <select
                className="form-control pointer"
                value={selected?.basis?.toUpperCase()}
                onChange={e => {
                  //console.log(e)
                  setSelected(e.target.value)
                }}
                // ref={register({ required: true })}
              >
                {paymentTypes?.map((pt, i) => {
                  return (
                    <option value={pt?.basis} key={i}>
                      {props.t(pt?.basis?.toUpperCase())}
                      {pt?.fixed + pt?.percentage !== 0
                        ? pt?.fixed !== 0
                          ? " + " + pt?.fixed + props.t(" QAR")
                          : " + " + pt?.percentage + props.t(" %")
                        : null}
                    </option>
                  )
                })}
              </select>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col sm={10}>
          <img
            src={
              "https://res.cloudinary.com/developerqa/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1733141348/merchant/Mastercard-Logo.wine_xi5bn7.svg"
            }
            width={"50px"}
            height={"50px"}
            alt="master logo"
          />
          <img
            src={
              "https://res.cloudinary.com/developerqa/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1733141228/merchant/Visa_Inc.-Logo.wine_fuqjvs.svg"
            }
            width={"50px"}
            height={"50px"}
            alt="visa logo"
          />

          <img
            src={
              "https://res.cloudinary.com/developerqa/image/upload/v1733141356/merchant/American_Express-Logo.wine_kcvada.svg"
            }
            width={"50px"}
            height={"50px"}
            alt="amex logo"
          />
        </Col>
        <Col> </Col>
      </Row>
      <div>
        <small>
          <a
            href={paymentRequestDetails?.termsConditionUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="color-primary"
          >
            {props.t("By paying, I agree to the TERMS & CONDITION")}
          </a>
        </small>
      </div>{" "}
    </React.Fragment>
  )
}
CardTypesCheckBox.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CardTypesCheckBox))
