import dateFormat from "helpers/commonhelpers/dateFormat"
import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { Col, Table, Label } from "reactstrap"
const RefundSuccessInvoice = props => {
  const { paidDetails, amount, currency } = props

  return (
    <>
      <Col lg="2"></Col>
      <Col lg="8">
        <div
          style={{
            background: "#EAEFF5",
            padding: "2%",
            borderRadius: "25px",
          }}
        >
          <h1>
            <Label className="mx-auto" style={{ ["color"]: "#34C38F" }}>
              {/* <i className="fas fa-check fa-2x" /> */}
              <br></br> {props.t("REFUND APPROVED")}{" "}
            </Label>
          </h1>
          <small>
            <Table
              style={{ textAlign: "center", marginBottom: "0 !important" }}
              className="table table-sm  table-borderless"
            >
              <tbody>
                <tr>
                  <td>{props.t("PAID DATE")}</td>
                  <td>{dateFormat(paidDetails?.paidDate)}</td>
                </tr>
                <tr>
                  <td>{props.t("PAID AMOUNT")}</td>
                  <td>
                    {props.t(currency)}{" "}
                    {amount.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{props.t("TRACK ID")}</td>
                  <td>{paidDetails?.trackId}</td>
                </tr>
              </tbody>
            </Table>
          </small>
        </div>
      </Col>
      <Col lg="2"> </Col>
    </>
  )
}

RefundSuccessInvoice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(RefundSuccessInvoice))
