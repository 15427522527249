import React from "react"
import Moment from "react-moment"
import { Button } from "reactstrap"
import {
  paymentRequestStatus,
  paymentRequestLinkStatus,
} from "../../helpers/paymentrequest/paymentrequest_helper"

import dateFormat from "../../helpers/commonhelpers/dateFormat"
const PaymentRequestColumns = handleOpenOverviewPrId => [
  {
    dataField: "paymentRequestId",
    text: "Payment Request Id",
    searchable: true,
    sort: true,
    formatter: (cellContent, row, rowIndex) => {
      return (
        <Button
          color="primary"
          outline
          className="waves-effect"
          style={{ borderColor: "#ffffff00" }}
          onClick={() => handleOpenOverviewPrId(cellContent)}
        >
          {cellContent}
        </Button>
      )
    },
  },

  {
    dataField: "paymentRequestDate",
    text: "Payment Request Date",
    searchable: false,
    sort: true,
    formatter: (cellContent, row) => {
      return <span>{dateFormat(cellContent)}</span>
    },
  },

  {
    text: "Customer Name",
    dataField: "customerDetails.name",
    sort: true,
    searchable: true,
    hidden: true,
  },
  {
    text: "Billing Name",
    dataField: "customerDetails",
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <b className="mb-1">{cellContent.name}</b>
        <p>
          <small className="mb-0">{cellContent.email}</small> <br />
          <small className="mb-0">{cellContent.mobile}</small>
        </p>
      </>
    ),
  },

  {
    text: "Amount",
    dataField: "amountDetails.totalAmount",
    sort: true,
    searchable: true,
  },
  {
    dataField: "amountDetails.currency",
    text: "Currency",
    sort: true,
    searchable: true,
  },

  {
    dataField: "paymentStatus",
    text: "Payment Status",
    sort: true,
    formatter: (cellContent, row) => {
      return paymentRequestStatus(cellContent)
    },
  },

  {
    dataField: "paymentRequestSettings.linkValidUpto",
    text: "Link Expiration",
    sort: true,
    searchable: false,
    formatter: (cellContent, row) => {
      return <span>{dateFormat(cellContent)}</span>
    },
  },

  {
    dataField: "itemSource",
    text: "Platform",
    searchable: true,
    sort: true,
  },
]

export default PaymentRequestColumns
