import { dateFormatString } from "helpers/commonhelpers/dateFormat"
import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Label,
  Input,
  Form,
} from "reactstrap"

import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import { setDibsyRefund } from "store/actions"

function RefundModal(props) {
  const {
    refundDetails,
    isOpen,
    toggle,
    paymentrequestDetails,
    onSetDibsyRefund,
  } = props

  const [refundDesc, setRefundDescp] = useState("")
  const { register, handleSubmit, errors } = useForm()
  useEffect(() => {
    // console.log(paymentrequestDetails)
  }, [paymentrequestDetails])
  useEffect(() => {}, [refundDesc])
  function handleConfirm() {
    let request = {
      transId: refundDetails.transId,
      amount: {
        currency: refundDetails.amountDetails.currency,
        value: refundDetails.amountDetails.totalAmount.toString(),
      },
      description: refundDesc,
    }
    toggle()
    if (refundDesc !== "") {
      onSetDibsyRefund(request)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <Label>
          Refund Payment: <b>{paymentrequestDetails.paymentRequestId}</b>
        </Label>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(handleConfirm)}>
              <Row className="mb-4">
                <Col>
                  Paid date:{" "}
                  <b>
                    {" "}
                    {dateFormatString(
                      paymentrequestDetails?.paidDetails?.paidDate
                    )}
                  </b>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h5 className="color-primary">Customer Details</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Name: {paymentrequestDetails.customerDetails.name}
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col>
                      Email: {paymentrequestDetails.customerDetails.email}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col>
                      Mobile: {paymentrequestDetails.customerDetails.mobile}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <h5 className="color-primary">Amount Details</h5>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col>
                      Total Amount:{" "}
                      <b className="color-danger">
                        {paymentrequestDetails.amountDetails.totalAmount}{" "}
                        {paymentrequestDetails.amountDetails.currency}
                      </b>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col>
                      Payment method:{" "}
                      {paymentrequestDetails.amountDetails.paymentType}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Label>
                    <b className="color-danger">*</b>Refund Description:{" "}
                  </Label>
                  <input
                    type="text"
                    id="refundDesc"
                    name="refundDesc"
                    className="form-control  form-control-lg input-text-size"
                    value={refundDesc}
                    ref={register({ required: true })}
                    onChange={e => {
                      setRefundDescp(e.target.value)
                    }}
                  />
                  {errors.refundDesc &&
                    errors.refundDesc.type === "required" && (
                      <p className="color-danger">This is required</p>
                    )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-3">
                <Col className="item-center">
                  <Button color="success" className="" type="sumbit">
                    Confirm
                  </Button>
                  <Button
                    color="danger"
                    className="mx-2"
                    onClick={() => {
                      toggle()
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = pr => ({
  paymentRequestDetail: pr.paymentRequests.paymentRequestDetail,
})

const mapDispatchToProps = dispatch => ({
  onSetDibsyRefund: data => dispatch(setDibsyRefund(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(RefundModal)
