import React, { useState, useContext, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
  Spinner,
  Button,
  Collapse,
} from "reactstrap"
//Import Flatepicker
import { Link } from "react-router-dom"
import { CSVLink } from "react-csv"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Toasttify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { PaymentRequestContext } from "./index"
//UseForm
import { useForm } from "react-hook-form"

const animatedComponents = makeAnimated()
const headersHistory = [
  { label: "Payment Request Id", key: "paymentRequestId" },
  { label: "Payment Gateway Id", key: "paymentHistory.paymentGatewayId" },
  { label: "Amount", key: "amountDetails.totalAmount" },
  { label: "Currency", key: "amountDetails.currency" },
  // { label: "Transaction Reference", key: "transactionReference" },
  // { label: "Last Payment", key: "lastPayment" },
  { label: "Generated  Link Short", key: "generatedLinkShort" },
  { label: "Generated Link Status", key: "generatedLinkStatus" },
  { label: "Payment Status", key: "paymentStatus" },
  { label: "Payment Types", key: "paymentRequestSettings.paymentType" },
  { label: "Language", key: "paymentRequestSettings.language" },
  { label: "Link Valid Up To", key: "paymentRequestSettings.linkValidUpto" },
  { label: "Link Validity", key: "paymentRequestSettings.linkValidity" },
  // { label: "PG Reference", key: "paymentRequestSettings.pgReference" },
  { label: "Customer Id", key: "customerDetails.customerId" },
  { label: "Customer Name", key: "customerDetails.name" },
  { label: "Customer Mobile", key: "customerDetails.mobile" },
  { label: "Customer Email", key: "customerDetails.email" },
  { label: "Customer Type", key: "customerDetails.type" },
  { label: "Merchant Id", key: "merchantDetails.merchantId" },
  { label: "Merchant Name", key: "merchantDetails.merchantName" },
  { label: "Username", key: "userDetails.userName" },
  { label: "User email", key: "userDetails.email" },
  { label: "Commission Type", key: "commission.type" },
  { label: "Commission Value", key: "commission.value" },
  { label: "Commission Total", key: "commission.totalCommission" },
  { label: "Paid Details", key: "paidDetails" },
  { label: "Payment Id", key: "paymentHistory.paymentId" },
  { label: "Payment Date", key: "paymentHistory.paymentDate" },
  { label: "Track Id", key: "paymentHistory.trackId" },
  { label: "Trans Id", key: "paymentHistory.transId" },
  { label: "Terminal Id", key: "paymentHistory.terminalId" },
  { label: "Payment Card", key: "paymentHistory.paymentType" },
  { label: "Tax Amount", key: "paymentHistory.taxAmount" },
  { label: "Payment Response Code", key: "paymentHistory.paymentResponseCode" },
  {
    label: "Payment Response Result",
    key: "paymentHistory.paymentResponseResult",
  },
  { label: "Is Successful", key: "paymentHistory.isSuccessful" },
  {
    label: "Additional Payment Info",
    key: "paymentHistory.additionalPaymentInfo",
  },
]
const optionGroup2 = [
  {
    label: "Payment Status",
    options: [
      { label: "Success", value: "Success" },
      { label: "Failed", value: "Failed" },
      { label: "Unpaid", value: "Unpaid" },
      { label: "Refund Approved", value: "Refund Approved" },
      { label: "Refund Pending", value: "Refund Pending" },
      { label: "Refund Failed", value: "Refund Failed" },
    ],
  },
  {
    label: "Source",
    options: [
      { label: "Portal", value: "PORTAL" },
      { label: "SellCo", value: "SELLCO" },
      { label: "POS", value: "POS" },
    ],
  },
  {
    label: "Link Status",
    options: [
      { label: "Active", value: "Active" },
      { label: "Expired", value: "Expired" },
    ],
  },
]

const whiteText = {
  color: "white",
}

const multi = {
  filtered: [],
  select2: undefined,
}

export default function SearchForm(props) {
  const { merchantList, downloadsList, loadingDownloadHistotry, userRole } =
    props
  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi)
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const { handleSubmit, register, errors, reset } = useForm()
  const {
    handleFilterActions,
    handleDownloadReport,
    handleDownloadReportHistory,
  } = useContext(PaymentRequestContext)
  const [downloadType, setDownloadType] = useState("prs")
  const [filterForm, setFilterForm] = useState(false)
  const optionMerchants = merchantList?.map((m, i) => {
    return { value: m.merchantId, label: m.merchantName }
  })
  useEffect(() => {}, [selectedMultiSelect, selectedDateRange])

  useEffect(() => {
    //console.log(downloadType)
  }, [downloadType])

  useEffect(() => {
    // console.log(merchantList)
  }, [merchantList])

  function collapseFilterForm() {
    setFilterForm(!filterForm)
  }

  function onFilteredChangeCustom(value, accessor) {
    let filtered = selectedMultiSelect.filtered
    let insertNewFilter = 1

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1)
          else filter["value"] = value

          insertNewFilter = 0
        }
      })
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value })
    }

    setselectedMultiSelect({ filtered: filtered })
  }

  function handleDateRange(selectedDates) {
    setSelectedDateRange(selectedDates)
  }

  const handleReportDownload = (merchant, dateRange) => {
    // console.log(merchant, dateRange)
    if (Array.isArray(merchant.filtered) && dateRange === null) {
      handleDownloadReportHistory(null, [
        "0001-01-01T00:00:00.000+00:00",
        "0001-01-01T00:00:00.000+00:00",
      ])
    } else if (Array.isArray(merchant.filtered) && dateRange !== null) {
      handleDownloadReportHistory(null, dateRange)
    } else if (!Array.isArray(merchant) && dateRange === null) {
      handleDownloadReportHistory(merchant, [
        "0001-01-01T00:00:00.000+00:00",
        "0001-01-01T00:00:00.000+00:00",
      ])
    } else {
      handleDownloadReportHistory(merchant, dateRange)
    }
  }

  function handleReset() {
    //setselectedMultiSelect({select2: undefined})
    //document.getElementsByClassName("multiSelect__value-container--has-value").innerHtml = null
    // document.querySelectorAll(
    //   '.multiSelect__value-container div:not(.css-b8ldur-Input):not(.multiSelect__input)').forEach(s => s.remove())
    //document.getElementsByClassName("multiSelect__clear-indicator").onClick()
    //  document.querySelector('.multiSelect__clear-indicator').click()
    window.location.reload()
    handleFilterActions([], [])
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-row-reverse">
          <div>
            {filterForm ? (
              <Link
                to="#"
                onClick={() => collapseFilterForm()}
                style={{ cursor: "pointer" }}
              >
                Hide Options
              </Link>
            ) : (
              <Link
                to="#"
                onClick={() => collapseFilterForm()}
                style={{ cursor: "pointer" }}
              >
                Show Options
              </Link>
            )}
          </div>
        </div>
        <Collapse isOpen={filterForm}>
          <Row>
            {userRole === "SuperAdmin" ? (
              <Col lg="2 p-0">
                {" "}
                <FormGroup className="mb-4 templating-select select2-container mx-2">
                  {/* <Row> */}
                  <label className="control-label">Category</label>{" "}
                  {/* </Row>
                  <Row> */}
                  <select
                    className="form-control"
                    onChange={e => {
                      setDownloadType(e.target.value)
                    }}
                  >
                    <option value="prs">Payment Links</option>
                    <option value="history">Payment History (Detailed)</option>
                  </select>
                  {/* </Row> */}
                </FormGroup>
              </Col>
            ) : null}

            {downloadType === "prs" ? (
              <>
                {" "}
                <Col lg="3">
                  <FormGroup className="mb-4">
                    <Label>Date Range</Label>
                    <InputGroup>
                      <Flatpickr
                        name="selectedDates"
                        className="form-control d-block"
                        placeholder="Select Date Range"
                        options={{
                          mode: "range",
                          altFormat: "Y-m-d",
                          dateFormat: "F j, Y",
                        }}
                        onChange={selectedDates =>
                          handleDateRange(selectedDates)
                        }
                        // ref={register({ required: true })}
                      />
                      <div
                        className="input-group-append"
                        onClick={() => handleReset()}
                      >
                        <Button type="button" color="danger">
                          <i className="mdi mdi-close-thick" />
                        </Button>
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="4">
                  {/* <FormGroup className="mb-0 templating-select select2-container"> */}

                  <Label> Filters</Label>
                  <InputGroup>
                    <Select
                      name="multiSelect"
                      id="multiSelect"
                      value={selectedMultiSelect.select2}
                      isMulti={true}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minWidth: "260px",
                          maxWidth: "400px",
                        }),
                      }}
                      onChange={entry => {
                        if (entry) {
                          setselectedMultiSelect({ select2: entry })
                          //  entry.map(o => {return onFilteredChangeCustom(o.value,o.label)})
                          onFilteredChangeCustom(
                            entry.map(o => {
                              return o.value
                            }),
                            "entry"
                          )
                        }
                      }}
                      options={optionGroup2}
                      // ref={register({ required: true })}
                      // options={selectedMultiSelect.data.map((o, i) => {
                      //   return { id: i, value: o.firstName, label: o.firstName };
                      // })}
                      classNamePrefix="multiSelect"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                    />
                    <div
                      className="input-group-append"
                      onClick={() => handleReset()}
                    >
                      <Button type="button" color="danger">
                        <i className="mdi mdi-close-thick" />
                      </Button>
                    </div>
                  </InputGroup>
                </Col>
                <Col lg="1" className="mr-5">
                  <FormGroup className="mb-0 templating-select select2-container">
                    {/* <Row> */}{" "}
                    <label className="control-label" style={whiteText}>
                      Button
                    </label>{" "}
                    {/* </Row>
                    <Row> */}
                    <button
                      type="button"
                      style={{ minWidth: "125px" }}
                      className="btn btn-primary waves-effect btn-label waves-light w-100"
                      onClick={() =>
                        handleFilterActions(
                          selectedMultiSelect,
                          selectedDateRange
                        )
                      }
                    >
                      <i className="bx bx-search-alt label-icon"></i>Search
                    </button>
                    {/* </Row> */}
                  </FormGroup>
                </Col>
              </>
            ) : (
              <>
                <Col lg="3">
                  <FormGroup className="mb-4">
                    <Label>Date Range</Label>
                    <InputGroup>
                      <Flatpickr
                        name="selectedDates"
                        className="form-control d-block"
                        placeholder="Select Date Range"
                        options={{
                          mode: "range",
                          altFormat: "Y-m-d",
                          dateFormat: "F j, Y",
                        }}
                        onChange={selectedDates =>
                          handleDateRange(selectedDates)
                        }
                        // ref={register({ required: true })}
                      />
                      <div
                        className="input-group-append"
                        onClick={() => handleReset()}
                      >
                        <Button type="button" color="danger">
                          <i className="mdi mdi-close-thick" />
                        </Button>
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup className="mb-0 templating-select select2-container">
                    <label className="control-label"> Merchant</label>
                    <InputGroup>
                      <Select
                        name="multiSelect"
                        id="multiSelect"
                        closeMenuOnSelect={true}
                        value={selectedMultiSelect.select2}
                        onChange={entry => {
                          setselectedMultiSelect(entry.value)
                        }}
                        options={optionMerchants}
                        classNamePrefix="multiSelectMerchants"
                        components={animatedComponents}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            minWidth: "260px",
                            maxWidth: "400px",
                          }),
                        }}
                      />
                      <div
                        className="input-group-append"
                        onClick={() => handleReset()}
                      >
                        <Button type="button" color="danger">
                          <i className="mdi mdi-close-thick" />
                        </Button>
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="1" className="mr-5">
                  <FormGroup className="mb-0 templating-select select2-container">
                    {/* <Row> */}{" "}
                    <label className="control-label" style={whiteText}>
                      Button
                    </label>{" "}
                    {/* </Row> */}
                    {loadingDownloadHistotry ? (
                      <Spinner type="border" className="mr-1" color="primary" />
                    ) : (
                      // <Row>
                      <button
                        type="button"
                        style={{ minWidth: "125px" }}
                        className="btn btn-primary waves-effect btn-label waves-light w-100"
                        onClick={() =>
                          handleReportDownload(
                            selectedMultiSelect,
                            selectedDateRange
                          )
                        }
                      >
                        <i className="bx bx-search-alt label-icon"></i> Search
                      </button>
                      // </Row>
                    )}
                  </FormGroup>
                </Col>
                {/* <Col lg="1">
                <Row>
                  {" "}
                  <label className="control-label" style={whiteText}>
                    Button
                  </label>{" "}
                </Row>
                {loadingDownloadHistotry ? (
                  <Spinner type="border" className="mr-1" color="primary" />
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-label waves-light w-md"
                    onClick={() =>
                      handleDownloadReportHistory(
                        selectedMultiSelect,
                        selectedDateRange
                      )
                    }
                  >
                    <i className="bx bx-search-alt label-icon"></i> Search
                  </button>
                )}
              </Col> */}
              </>
            )}
            <Col lg="1" className="mr-5">
              <FormGroup className="mb-0 templating-select select2-container">
                {/* <Row> */}{" "}
                <label className="control-label" style={whiteText}>
                  Button
                </label>{" "}
                {/* </Row> */}
                {downloadType === "prs" ? (
                  // <Row>
                  <button
                    type="button"
                    style={{ minWidth: "125px" }}
                    className="btn btn-success waves-effect btn-label waves-light w-100"
                    onClick={() =>
                      handleDownloadReport(
                        selectedMultiSelect,
                        selectedDateRange
                      )
                    }
                  >
                    <i className="bx bx-download label-icon"></i> Download
                  </button>
                ) : (
                  // </Row>
                  <>
                    {Array.isArray(downloadsList) &&
                    downloadsList.length !== 0 ? (
                      <CSVLink
                        color="warning"
                        style={{ minWidth: "125px" }}
                        filename={
                          "PaymentHistory" +
                          dateFormatString(new Date()) +
                          ".csv"
                        }
                        className="btn btn-success waves-effect btn-label waves-light w-100 mr-5"
                        data={downloadsList}
                        separator="," //, or ;
                        headers={headersHistory}
                        key="_id"
                        target="_blank"
                      >
                        <i className="bx bx-download label-icon" />
                        Download
                      </CSVLink>
                    ) : null}
                  </>
                )}
                {/* <label className="control-label" style={whiteText}> Download CSV</label> */}
              </FormGroup>
            </Col>

            {/* <Col lg="1">
            <FormGroup className="mb-0 templating-select select2-container">
              <Row>
                <label className="control-label" style={whiteText}>
                  Reset
                </label>{" "}
              </Row>
              <Row>
                <button
                  type="button"
                  className="btn btn-danger waves-effect btn-label waves-light w-md"
                  onClick={() => handleReset()}
                >
                  <i className="bx bx-reset label-icon "></i> Reset
                </button>
              </Row>
            </FormGroup>
          </Col> */}
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  )
}
