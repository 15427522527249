import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PAYMENTREQUEST_LIST,
  GET_PAYMENTREQUEST_LIST_REPORT,
  GET_PAYMENTREQUEST_DETAIL,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC,
  SET_DEVICE_FINGERPRINT,
  SET_PAYMENT_REQUEST,
  SET_PAYMENT_METHOD,
  SEND_PAYMENT_REQUEST_EMAIL,
  UPDATE_PAYMNET_REQUEST,
  SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
  SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
  UPDATE_PAYMENT_REQUEST_BOOLEAN,
  GET_USER_PAYMENT_REQUESTS_BY_USERID,
  FILE_UPLOAD,
  SET_PAYMENT_REQUEST_EXPRESS,
  SET_MYFATOORAH_INITIATE_PAYMENT,
  SET_DIBSY_INITIATE_PAYMENT,
  SET_DIBSY_INITIATE_SESSION_APPLEPAY,
  GET_PAYMENT_METHOD,
  SET_DIBSY_REFUND,
} from "./actionTypes"
import {
  getPaymentRequestListSuccess,
  getPaymentRequestListFail,
  getPaymentRequestListReportSuccess,
  getPaymentRequestListReportFail,
  getPaymentRequestDetailSuccess,
  getPaymentRequestDetailFail,
  getPaymentRequestDetailPublicSuccess,
  getPaymentRequestDetailPublicFail,
  setDeviceFingerPrintSuccess,
  setDeviceFingerPrintFail,
  setPaymentRequestSuccess,
  setPaymentRequestFail,
  setPaymentMethodSuccess,
  setPaymentMethodFail,
  sendPaymentRequestEmailSuccess,
  sendPaymentRequestEmailFail,
  setPaymentRequestEmailNonExpressSuccess,
  setPaymentRequestEmailNonExpressFail,
  setPaymentRequestEmailExpressSuccess,
  setPaymentRequestEmailExpressFail,
  updatePaymentRequestSuccess,
  updatePaymentRequestFail,
  updatePaymentRequestBooleanSuccess,
  updatePaymentRequestBooleanFalse,
  restartState,
  getPayReqByUserIdSuccess,
  getPayReqByUserIdFail,
  fileUploadSuccess,
  fileUploadFail,
  setPaymentRequestExpressSuccess,
  setPaymentRequestExpressFail,
  setMyfatoorahInitiatePaymentSuccess,
  setMyfatoorahInitiatePaymentFail,
  setDibsyInitiatePaymentSuccess,
  setDibsyInitiatePaymentFail,
  setDibsyInitiateSessionApplepaySuccess,
  setDibsyInitiateSessionApplepayFail,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  setDibsyRefundFail,
  setDibsyRefundSucess,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getPaymentRequestList,
  getPaymentRequestsDetails,
  getPaymentRequestsDetailsPublic,
  setDeviceFingerPrint,
  setPaymentRequest,
  setPaymentMethod,
  setMyfatoorahInitiatePayment,
  setDibsyInitiatePayment,
  setDibsyInitiateSessionApplepay,
  updatePaymentRequest,
  sendPaymentRequestEmail,
  getPRByUserId,
  getPaymentRequestListReport,
  uploadFileAttachment,
  getPaymentMethod,
  setDibsyRefund,
} from "helpers/fakebackend_helper"

function* _getPaymentRequestList({ merchantId }) {
  try {
    const response = yield call(getPaymentRequestList, merchantId)

    if (response) {
      if (response.isSuccess)
        yield put(getPaymentRequestListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentRequestListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getPaymentRequestListFail(response))
        }
      }
    } else
      yield put(
        getPaymentRequestListFail({
          message: merchantId + " Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getPaymentRequestListFail(error?.message))
  }

  //yield put(restartState())
}
function* _getPaymentMethod({ payload: data }) {
  try {
    const response = yield call(getPaymentMethod, data)

    if (response) {
      if (response.isSuccess) yield put(getPaymentMethodSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentMethodFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getPaymentMethodFail(response))
        }
      }
    } else
      yield put(
        getPaymentMethodFail({
          message: merchantId + " Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getPaymentMethodFail(error?.message))
  }

  //yield put(restartState())
}

function* _getPaymentRequestDetail({ id }) {
  try {
    const response = yield call(getPaymentRequestsDetails, id)

    if (response) {
      if (response.isSuccess)
        yield put(getPaymentRequestDetailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentRequestDetailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getPaymentRequestDetailFail(response))
        }
      }
    } else
      yield put(
        getPaymentRequestDetailFail({ message: id + " Invalid Response." })
      )
  } catch (error) {
    yield put(getPaymentRequestDetailFail(error?.message))
  }
  yield put(restartState())
}

function* _getPaymentRequestDetailPublic({ id }) {
  try {
    const response = yield call(getPaymentRequestsDetailsPublic, id)
    if (response) {
      if (response?.isSuccess)
        yield put(getPaymentRequestDetailPublicSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentRequestDetailPublicFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getPaymentRequestDetailPublicFail(response))
        }
      }
    } else
      yield put(
        getPaymentRequestDetailPublicFail({
          message: id + " Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getPaymentRequestDetailPublicFail(error?.message))
  }
}

function* _setDeviceFingerPrint({ payload: data }) {
  try {
    const response = yield call(setDeviceFingerPrint, data)

    if (response) {
      if (response?.isSuccess)
        yield put(setDeviceFingerPrintSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setDeviceFingerPrintFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setDeviceFingerPrintFail(response))
        }
      }
    } else yield put(setDeviceFingerPrintFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(setDeviceFingerPrintFail(error?.message))
  }
}

function* _setPaymentRequest({ payload: paymentRequest }) {
  try {
    const response = yield call(setPaymentRequest, paymentRequest)

    if (response) {
      if (response?.isSuccess)
        yield put(setPaymentRequestSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setPaymentRequestFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setPaymentRequestFail(response.message))
      }
    } else yield put(setPaymentRequestFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestFail(error?.message))
  }
}

function* _setPaymentMethod({ payload: chosenPaymentMethod }) {
  try {
    const response = yield call(setPaymentMethod, chosenPaymentMethod)

    if (response) {
      if (response?.isSuccess) yield put(setPaymentMethodSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setPaymentMethodFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setPaymentMethodFail(response.message))
      }
    } else yield put(setPaymentMethodFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(setPaymentMethodFail(error?.message))
  }
}

function* _sendPaymentRequestEmail({ payload: data }) {
  try {
    const response = yield call(sendPaymentRequestEmail, data)

    if (response) {
      if (response?.isSuccess)
        yield put(sendPaymentRequestEmailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              sendPaymentRequestEmailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(sendPaymentRequestEmailFail(response.message))
      }
    } else
      yield put(sendPaymentRequestEmailFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(sendPaymentRequestEmailFail(error?.message))
  }
}

function* _sendPaymentRequestEmailNonExpress({ payload: data }) {
  try {
    const response = yield call(sendPaymentRequestEmail, data)

    if (response) {
      if (response?.isSuccess)
        yield put(setPaymentRequestEmailNonExpressSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setPaymentRequestEmailNonExpressFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setPaymentRequestEmailNonExpressFail(response.message))
      }
    } else
      yield put(
        setPaymentRequestEmailNonExpressFail({ message: "Invalid Response." })
      )

    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestEmailNonExpressFail(error?.message))
  }
}

function* _sendPaymentRequestEmailExpress({ payload: data }) {
  try {
    const response = yield call(sendPaymentRequestEmail, data)

    if (response) {
      if (response?.isSuccess)
        yield put(setPaymentRequestEmailExpressSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setPaymentRequestEmailExpressFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setPaymentRequestEmailExpressFail(response.message))
      }
    } else
      yield put(
        setPaymentRequestEmailExpressFail({ message: "Invalid Response." })
      )

    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestEmailExpressFail(error?.message))
  }
}

function* _updatePaymentRequestBoolean({ payload: data }) {
  try {
    yield put(updatePaymentRequestBooleanSuccess(data))
  } catch (error) {
    yield put(updatePaymentRequestBooleanFalse(error))
  }
}

function* _updatePaymentRequest({ payload: paymentRequest }) {
  try {
    const response = yield call(updatePaymentRequest, paymentRequest)

    if (response) {
      if (response?.isSuccess)
        yield put(updatePaymentRequestSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updatePaymentRequestFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updatePaymentRequestFail(response.message))
      }
    } else yield put(updatePaymentRequestFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(updatePaymentRequestFail(error?.message))
  }
}

function* _getPayReqByUserId({ payload: data }) {
  try {
    const response = yield call(getPRByUserId, data)

    if (response) {
      if (response?.isSuccess)
        yield put(getPayReqByUserIdSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPayReqByUserIdFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getPayReqByUserIdFail(response.message))
      }
    } else
      yield put(getPayReqByUserIdFail({ message: data + " Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(getPayReqByUserIdFail(error?.message))
  }
}

// function* setSearchValue({payload:data}){
//   try{
//     yield put(setMainSearch(data))
//   }
//   catch(error){

//   }
// }

function* _getPaymentRequestsListReport({ merchantId }) {
  try {
    const response = yield call(getPaymentRequestListReport, merchantId)

    if (response) {
      if (response?.isSuccess)
        yield put(getPaymentRequestListReportSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentRequestListReportFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getPaymentRequestListReportFail(response.message))
      }
    } else
      yield put(
        getPaymentRequestListReportFail({
          message: merchantId + " Invalid Response.",
        })
      )

    yield put(restartState())
  } catch (error) {
    yield put(getPaymentRequestListReportFail(error?.message))
  }
}

function* _fileUpload({ payload: files }) {
  try {
    const response = yield call(uploadFileAttachment, files)

    if (response) {
      if (response?.isSuccess) yield put(fileUploadSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              fileUploadFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(fileUploadFail(response.message))
      }
    } else yield put(fileUploadFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(fileUploadFail(error?.message))
  }
}

function* _setPaymentRequestExpress({ payload: paymentRequest }) {
  // try {
  //   const response = yield call(setPaymentRequest, paymentRequest)
  //   if (response.data) {
  //     yield put(setPaymentRequestExpressSuccess(response.data))
  //   } else {
  //     yield put(setPaymentRequestExpressFail(response.data))
  //   }
  //   yield put(restartState())
  // } catch (error) {
  //   yield put(setPaymentRequestExpressFail(error))
  // }
  try {
    const response = yield call(setPaymentRequest, paymentRequest)

    if (response) {
      if (response?.isSuccess)
        yield put(setPaymentRequestExpressSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setPaymentRequestExpressFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setPaymentRequestExpressFail(response.message))
      }
    } else
      yield put(setPaymentRequestExpressFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestExpressFail(error?.message))
  }
}

function* _setMyFatoorahInitiatePayment({ payload: data }) {
  try {
    const response = yield call(setMyfatoorahInitiatePayment, data)

    if (response) {
      if (response?.isSuccess)
        yield put(setMyfatoorahInitiatePaymentSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setMyfatoorahInitiatePaymentFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setMyfatoorahInitiatePaymentFail(response))
        }
      }
    } else
      yield put(
        setMyfatoorahInitiatePaymentFail({ message: "Invalid Response." })
      )
  } catch (error) {
    yield put(setMyfatoorahInitiatePaymentFail(error?.message))
  }
}

function* _setDibsyInitiatePayment({ payload: data }) {
  try {
    const response = yield call(setDibsyInitiatePayment, data)

    if (response) {
      if (response?.isSuccess)
        yield put(setDibsyInitiatePaymentSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setDibsyInitiatePaymentFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setDibsyInitiatePaymentFail(response))
        }
      }
    } else
      yield put(setDibsyInitiatePaymentFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setDibsyInitiatePaymentFail(error?.message))
  }
}

function* _setDibsyInitiateSessionApplepay({ merchantId }) {
  try {
    const response = yield call(setDibsyInitiateSessionApplepay, merchantId)

    if (response) {
      if (response?.isSuccess)
        yield put(setDibsyInitiateSessionApplepaySuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setDibsyInitiateSessionApplepayFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setDibsyInitiateSessionApplepayFail(response))
        }
      }
    } else
      yield put(
        setDibsyInitiateSessionApplepayFail({ message: "Invalid Response." })
      )
  } catch (error) {
    yield put(setDibsyInitiateSessionApplepayFail(error?.message))
  }
}

function* _setDibsyRefund({ payload: data }) {
  try {
    const response = yield call(setDibsyRefund, data)

    if (response) {
      if (response?.isSuccess) yield put(setDibsyRefundSucess(response.message))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setDibsyRefundFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setDibsyRefundFail(response))
        }
      }
    } else yield put(setDibsyRefundFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setDibsyRefundFail(error?.message))
  }
}

function* paymentrequestsSaga() {
  yield takeEvery(GET_PAYMENTREQUEST_LIST, _getPaymentRequestList)
  yield takeEvery(GET_PAYMENT_METHOD, _getPaymentMethod)
  yield takeEvery(GET_PAYMENTREQUEST_DETAIL, _getPaymentRequestDetail)
  yield takeEvery(
    GET_PAYMENTREQUEST_DETAIL_PUBLIC,
    _getPaymentRequestDetailPublic
  )
  yield takeEvery(SET_DEVICE_FINGERPRINT, _setDeviceFingerPrint)
  yield takeLatest(SET_PAYMENT_REQUEST, _setPaymentRequest)
  yield takeEvery(SET_PAYMENT_METHOD, _setPaymentMethod)
  yield takeEvery(UPDATE_PAYMNET_REQUEST, _updatePaymentRequest)
  yield takeEvery(SEND_PAYMENT_REQUEST_EMAIL, _sendPaymentRequestEmail)
  yield takeEvery(
    SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
    _sendPaymentRequestEmailNonExpress
  )
  yield takeEvery(
    SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
    _sendPaymentRequestEmailExpress
  )
  yield takeEvery(UPDATE_PAYMENT_REQUEST_BOOLEAN, _updatePaymentRequestBoolean)
  yield takeEvery(GET_USER_PAYMENT_REQUESTS_BY_USERID, _getPayReqByUserId)
  yield takeEvery(GET_PAYMENTREQUEST_LIST_REPORT, _getPaymentRequestsListReport)
  yield takeEvery(FILE_UPLOAD, _fileUpload)
  yield takeEvery(SET_PAYMENT_REQUEST_EXPRESS, _setPaymentRequestExpress)
  yield takeEvery(
    SET_MYFATOORAH_INITIATE_PAYMENT,
    _setMyFatoorahInitiatePayment
  )
  yield takeEvery(SET_DIBSY_INITIATE_PAYMENT, _setDibsyInitiatePayment)
  yield takeEvery(
    SET_DIBSY_INITIATE_SESSION_APPLEPAY,
    _setDibsyInitiateSessionApplepay
  )
  yield takeEvery(SET_DIBSY_REFUND, _setDibsyRefund)
}

export default paymentrequestsSaga
