import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import dateFormat from "../../helpers/commonhelpers/dateFormat"
import PHRawResponseModal from "./PHRawResponseModal"
const PaymentHistory = props => {
  const { paymentHistoryDetails, user } = props
  // console.log(paymentHistoryDetails)
  const [modal, setModal] = useState(false)
  const [rawResponse, setRawResponse] = useState("")

  const toggleModal = () => {
    setModal(!modal)
  }
  useEffect(() => {}, [rawResponse])
  return (
    <>
      <PHRawResponseModal
        isOpen={modal}
        toggle={toggleModal}
        rawResponse={rawResponse}
      />
      <Card>
        <CardBody>
          <Row>
            <Col lg="12">
              <CardTitle className="mb-4">Payment History</CardTitle>
              <p className="text-muted mb-4">
                {/* {userProfile.personalDetail} */}
              </p>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th>
                        <i className="fas fa-calendar-alt text-success mr-1" />
                        Date{" "}
                      </th>
                      <th>
                        <i className="fas fa-file-alt  text-success mr-1" />
                        TransId
                      </th>
                      <th>
                        <i className="fas fa-file-alt  text-success mr-1" />
                        TrackId
                      </th>
                      <th>
                        <i className="fas fas fa-code text-success mr-1" />
                        PG
                      </th>
                      <th>
                        <i className="fas fa-file-alt text-success mr-1" />
                        Response
                      </th>
                      <th>
                        <i className="fas fas fa-credit-card text-success mr-1" />
                        Type
                      </th>
                      <th>
                        <i className="fas fas fa-info-circle text-success mr-1" />
                        More
                      </th>
                    </tr>

                    {paymentHistoryDetails.map((paymenthistory, index) => (
                      <tr key={index}>
                        <td>
                          <small>
                            {dateFormat(paymenthistory.paymentDate)}
                          </small>
                        </td>
                        <td>
                          {user.role === "SuperAdmin" &&
                          paymenthistory?.raw_response ? (
                            <small
                              // className="color-primary"
                              style={{ color: "#657ce7" }}
                              onClick={() => {
                                toggleModal()
                                setRawResponse(paymenthistory?.raw_response)
                              }}
                            >
                              <a>{paymenthistory.transId} </a>
                            </small>
                          ) : (
                            <small>{paymenthistory.transId}</small>
                          )}
                        </td>
                        <td>
                          <Link
                            to={"/paymentresult/" + paymenthistory.trackId}
                            target="_blank"
                          >
                            <small>{paymenthistory.trackId}</small>
                          </Link>
                        </td>
                        <td>
                          <small>{paymenthistory.paymentGatewayId}</small>
                        </td>
                        <td>
                          <small>
                            {paymenthistory.paymentResponseCode}-
                            {paymenthistory.paymentResponseResult}
                          </small>
                        </td>
                        <td>
                          <small>{paymenthistory.paymentType}</small>
                        </td>
                        <td>
                          <small>{paymenthistory.additionalPaymentInfo}</small>
                        </td>
                      </tr>
                    ))}

                    {/* <tr>
                        
                          <td>{userDetails.firstName} <p>{userDetails.email}</p></td>
                          </tr> */}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default PaymentHistory
