import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import CreditEmbedded from "./CreditEmbedded"
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  Collapse,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import DebitRedict from "./DebitRedirect"
import ApplePayEmbedded from "./ApplePayEmbedded"
import GooglePayEmbedded from "./GooglePayEmbedded"
import ApplePayRedirect from "./ApplePayRedirect"

export const Dibsy = props => {
  const {
    paymentRequestDetails,
    dbsyCreditEmbedded,
    dbsyDebitRedirect,
    dbsyApplePayEmbedded,
    dbsyGooglePayEmbedded,
    dbsyApplePayEmbeddedMerchantSession,
    selectCard,
  } = props

  const [creditCard, setCreditCard] = useState(false)
  const [debitCard, setDebitCard] = useState(false)
  const [applePayCard, setApplePayCard] = useState(false)

  const creditCollapse = () => {
    setCreditCard(!creditCard)
    setDebitCard(false)
    setApplePayCard(false)
  }

  function isSafariBrowser() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    return isSafari
  }

  const debitCollapse = () => {
    setDebitCard(!debitCard)
    setCreditCard(false)
    setApplePayCard(false)
  }

  const applePayCollapse = () => {
    setApplePayCard(!applePayCard)
    setCreditCard(false)
    setDebitCard(false)
  }

  return (
    <React.Fragment>
      <Col lg="12">
        {selectCard ? (
          <>
            {" "}
            {/* CREDIT CARD ________________________________________________________  */}
            {dbsyCreditEmbedded && selectCard?.basis === "credit" && (
              <>
                {paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("credit") && (
                  <>
                    <Button
                      width="100%"
                      onClick={creditCollapse}
                      className={classnames(
                        "btn-block inner mb-2",
                        "fw-medium",
                        {
                          collapsed: !creditCard,
                        }
                      )}
                      color="success"
                    >
                      {/* <i className="far fa-credit-card" />  */}
                      <span
                        style={{
                          border: "1px solid",
                          borderRadius: "0.15rem",
                          padding: "2px",
                          fontSize: "7px",
                          fontStyle: "italic",
                          verticalAlign: "middle",
                        }}
                      >
                        <b>___</b>
                      </span>
                      <span style={{ fontSize: "1rem" }}>
                        {props.t(" CREDIT CARD")}
                      </span>
                    </Button>

                    <Collapse isOpen={creditCard} className="p-3">
                      <CreditEmbedded
                        paymentRequestDetails={paymentRequestDetails}
                        dbsyCreditEmbedded={dbsyCreditEmbedded}
                      />

                      <hr></hr>
                    </Collapse>
                  </>
                )}
              </>
            )}
            {/* DEBIT CARD__________________________________________________________________ */}
            {dbsyDebitRedirect && selectCard?.basis === "debit" && (
              <>
                {props.paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("debit") && (
                  <>
                    <DebitRedict
                      dbsyDebitRedirect={dbsyDebitRedirect}
                      paymentRequestDetails={paymentRequestDetails}
                    />
                  </>
                )}
              </>
            )}
            {/* APPLE PAY CARD___________________________________________________________ */}
            {dbsyApplePayEmbedded &&
              isSafariBrowser() &&
              selectCard?.basis === "applepay" && (
                <>
                  {props.paymentRequestDetails?.paymentRequestSettings.paymentType
                    .toLowerCase()
                    .includes("applepay") && (
                    <>
                      <ApplePayEmbedded
                        dbsyApplePayEmbedded={dbsyApplePayEmbedded}
                        paymentRequestDetails={paymentRequestDetails}
                        dbsyApplePayEmbeddedMerchantSession={
                          dbsyApplePayEmbeddedMerchantSession
                        }
                      />
                      {/* <ApplePayRedirect dbsyApplePayEmbedded={dbsyApplePayEmbedded} paymentRequestDetails={paymentRequestDetails}/> */}
                    </>
                  )}
                </>
              )}
            {/* GOOGLE PAY CARD___________________________________________________________ */}
            {dbsyGooglePayEmbedded && selectCard?.basis === "googlepay" && (
              <>
                {props.paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("googlepay") && (
                  <>
                    <GooglePayEmbedded
                      dbsyGooglePayEmbedded={dbsyGooglePayEmbedded}
                      paymentRequestDetails={paymentRequestDetails}
                    />
                    {/* <ApplePayRedirection mfApplePayEmbedded={mfApplePayEmbedded} paymentRequestDetails={paymentRequestDetails}/> */}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {" "}
            {/* CREDIT CARD ________________________________________________________  */}
            {dbsyCreditEmbedded && (
              <>
                {paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("credit") && (
                  <>
                    <Button
                      width="100%"
                      onClick={creditCollapse}
                      className={classnames(
                        "btn-block inner mb-2",
                        "fw-medium",
                        {
                          collapsed: !creditCard,
                        }
                      )}
                      color="success"
                    >
                      {/* <i className="far fa-credit-card" />  */}
                      <span
                        style={{
                          border: "1px solid",
                          borderRadius: "0.15rem",
                          padding: "2px",
                          fontSize: "7px",
                          fontStyle: "italic",
                          verticalAlign: "middle",
                        }}
                      >
                        <b>___</b>
                      </span>
                      <span style={{ fontSize: "1rem" }}>
                        {props.t(" CREDIT CARD")}
                      </span>
                    </Button>

                    <Collapse isOpen={creditCard} className="p-3">
                      <CreditEmbedded
                        paymentRequestDetails={paymentRequestDetails}
                        dbsyCreditEmbedded={dbsyCreditEmbedded}
                      />

                      <hr></hr>
                    </Collapse>
                  </>
                )}
              </>
            )}
            {/* DEBIT CARD__________________________________________________________________ */}
            {dbsyDebitRedirect && (
              <>
                {props.paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("debit") && (
                  <>
                    <DebitRedict
                      dbsyDebitRedirect={dbsyDebitRedirect}
                      paymentRequestDetails={paymentRequestDetails}
                    />
                  </>
                )}
              </>
            )}
            {/* APPLE PAY CARD___________________________________________________________ */}
            {dbsyApplePayEmbedded && isSafariBrowser() && (
              <>
                {props.paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("applepay") && (
                  <>
                    <ApplePayEmbedded
                      dbsyApplePayEmbedded={dbsyApplePayEmbedded}
                      paymentRequestDetails={paymentRequestDetails}
                      dbsyApplePayEmbeddedMerchantSession={
                        dbsyApplePayEmbeddedMerchantSession
                      }
                    />
                    {/* <ApplePayRedirect dbsyApplePayEmbedded={dbsyApplePayEmbedded} paymentRequestDetails={paymentRequestDetails}/> */}
                  </>
                )}
              </>
            )}
            {/* GOOGLE PAY CARD___________________________________________________________ */}
            {dbsyGooglePayEmbedded && (
              <>
                {props.paymentRequestDetails?.paymentRequestSettings.paymentType
                  .toLowerCase()
                  .includes("googlepay") && (
                  <>
                    <GooglePayEmbedded
                      dbsyGooglePayEmbedded={dbsyGooglePayEmbedded}
                      paymentRequestDetails={paymentRequestDetails}
                    />
                    {/* <ApplePayRedirection mfApplePayEmbedded={mfApplePayEmbedded} paymentRequestDetails={paymentRequestDetails}/> */}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Col>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

Dibsy.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(Dibsy))
