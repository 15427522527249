import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Col, Table, Label } from "reactstrap"

const ExpiredInvoice = props => {
  const { amount, currency } = props

  return (
    <>
      <Col lg="2"></Col>
      <Col lg="8">
        <div
          style={{
            background: "#EAEFF5",
            padding: "2%",
            borderRadius: "25px",
          }}
        >
          <h4>
            <Label className="mx-auto" style={{ ["color"]: "#ce0058" }}>
              <i className="bx bx-unlink fa-2x" /> <br></br>
              {props.t("LINK IS EXPIRED")}{" "}
            </Label>
          </h4>
          <small>
            {props.t(
              "You have not paid within the time limit. Please request for a new link to pay"
            )}
          </small>

          <Table
            style={{ textAlign: "center" }}
            className="table table-sm  table-borderless"
          >
            <tbody>
              <tr>
                <td>
                  <b>
                    {props.t("AMOUNT DUE")} {props.t(currency)}{" "}
                    {amount.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
      <Col lg="2"> </Col>
    </>
  )
}

ExpiredInvoice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ExpiredInvoice))
