import {
  GET_PAYMENTREQUEST_LIST,
  GET_PAYMENTREQUEST_LIST_FAIL,
  GET_PAYMENTREQUEST_LIST_SUCCESS,
  GET_PAYMENTREQUEST_LIST_REPORT,
  GET_PAYMENTREQUEST_LIST_REPORT_FAIL,
  GET_PAYMENTREQUEST_LIST_REPORT_SUCCESS,
  GET_PAYMENTREQUEST_DETAIL,
  GET_PAYMENTREQUEST_DETAIL_FAIL,
  GET_PAYMENTREQUEST_DETAIL_SUCCESS,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL,
  SET_DEVICE_FINGERPRINT,
  SET_DEVICE_FINGERPRINT_SUCCESS,
  SET_DEVICE_FINGERPRINT_FAIL,
  SET_PAYMENT_REQUEST,
  SET_PAYMENT_REQUEST_SUCCESS,
  SET_PAYMENT_REQUEST_FAIL,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD_SUCCESS,
  SET_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMNET_REQUEST,
  UPDATE_PAYMNET_REQUEST_SUCCESS,
  UPDATE_PAYMNET_REQUEST_FAIL,
  SEND_PAYMENT_REQUEST_EMAIL,
  SEND_PAYMENT_REQUEST_EMAIL_SUCCESS,
  SEND_PAYMENT_REQUEST_EMAIL_FAIL,
  SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
  SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_SUCCESS,
  SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_FAIL,
  SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
  SET_PAYMENT_REQUEST_EMAIL_EXPRESS_SUCCESS,
  SET_PAYMENT_REQUEST_EMAIL_EXPRESS_FAIL,
  RESTART_STATE,
  UPDATE_PAYMENT_REQUEST_BOOLEAN,
  UPDATE_PAYMENT_REQUEST_BOOLEAN_SUCCESS,
  UPDATE_PAYMENT_REQUEST_BOOLEAN_FAIL,
  GET_USER_PAYMENT_REQUESTS_BY_USERID,
  GET_USER_PAYMENT_REQUESTS_BY_USERID_SUCCESS,
  GET_USER_PAYMENT_REQUESTS_BY_USERID_FAIL,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  SET_PAYMENT_REQUEST_EXPRESS,
  SET_PAYMENT_REQUEST_EXPRESS_SUCCESS,
  SET_PAYMENT_REQUEST_EXPRESS_FAIL,
  SET_MYFATOORAH_INITIATE_PAYMENT,
  SET_MYFATOORAH_INITIATE_PAYMENT_SUCCESS,
  SET_MYFATOORAH_INITIATE_PAYMENT_FAIL,
  SET_DIBSY_INITIATE_PAYMENT,
  SET_DIBSY_INITIATE_PAYMENT_SUCCESS,
  SET_DIBSY_INITIATE_PAYMENT_FAIL,
  SET_DIBSY_INITIATE_SESSION_APPLEPAY,
  SET_DIBSY_INITIATE_SESSION_APPLEPAY_SUCCESS,
  SET_DIBSY_INITIATE_SESSION_APPLEPAY_FAIL,
  SET_PAYMENT_TYPES,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
  SET_DIBSY_REFUND,
  SET_DIBSY_REFUND_SUCCESS,
  SET_DIBSY_REFUND_FAIL,
} from "./actionTypes"

export const getPaymentRequestList = merchantId => ({
  type: GET_PAYMENTREQUEST_LIST,
  merchantId,
})

export const getPaymentRequestListSuccess = paymentRequests => ({
  type: GET_PAYMENTREQUEST_LIST_SUCCESS,
  payload: paymentRequests,
})

export const getPaymentRequestListFail = error => ({
  type: GET_PAYMENTREQUEST_LIST_FAIL,
  payload: error,
})

export const getPaymentMethod = data => ({
  type: GET_PAYMENT_METHOD,
  payload: data,
})

export const getPaymentMethodSuccess = data => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  payload: data,
})

export const getPaymentMethodFail = error => ({
  type: GET_PAYMENT_METHOD_FAIL,
  payload: error,
})

export const getPaymentRequestListReport = merchantId => ({
  type: GET_PAYMENTREQUEST_LIST_REPORT,
  merchantId,
})

export const getPaymentRequestListReportSuccess = paymentRequestsReport => ({
  type: GET_PAYMENTREQUEST_LIST_REPORT_SUCCESS,
  payload: paymentRequestsReport,
})

export const getPaymentRequestListReportFail = error => ({
  type: GET_PAYMENTREQUEST_LIST_REPORT_FAIL,
  payload: error,
})

export const getPaymentRequestDetail = id => ({
  type: GET_PAYMENTREQUEST_DETAIL,
  id,
})

export const getPaymentRequestDetailSuccess = paymentrequestDetails => ({
  type: GET_PAYMENTREQUEST_DETAIL_SUCCESS,
  payload: paymentrequestDetails,
})

export const getPaymentRequestDetailFail = error => ({
  type: GET_PAYMENTREQUEST_DETAIL_FAIL,
  payload: error,
})

export const getPaymentRequestDetailPublic = id => ({
  type: GET_PAYMENTREQUEST_DETAIL_PUBLIC,
  id,
})

export const getPaymentRequestDetailPublicSuccess = paymentrequestDetails => ({
  type: GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS,
  payload: paymentrequestDetails,
})

export const getPaymentRequestDetailPublicFail = error => ({
  type: GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL,
  payload: error,
})

export const setDeviceFingerPrint = data => ({
  type: SET_DEVICE_FINGERPRINT,
  payload: data,
})

export const setDeviceFingerPrintSuccess = deviceFingerPrint => ({
  type: SET_DEVICE_FINGERPRINT_SUCCESS,
  payload: deviceFingerPrint,
})

export const setDeviceFingerPrintFail = error => ({
  type: SET_DEVICE_FINGERPRINT_FAIL,
  payload: error,
})

export const setPaymentRequest = paymentRequests => ({
  type: SET_PAYMENT_REQUEST,
  payload: paymentRequests,
})

export const setPaymentRequestSuccess = paymentRequests => ({
  type: SET_PAYMENT_REQUEST_SUCCESS,
  payload: paymentRequests,
})

export const setPaymentRequestFail = paymentRequests => ({
  type: SET_PAYMENT_REQUEST_FAIL,
  payload: paymentRequests,
})

export const setPaymentMethod = chosenPaymentMethod => ({
  type: SET_PAYMENT_METHOD,
  payload: chosenPaymentMethod,
})

export const setPaymentMethodSuccess = paymentRedirectionLink => ({
  type: SET_PAYMENT_METHOD_SUCCESS,
  payload: paymentRedirectionLink,
})
export const setPaymentMethodFail = error => ({
  type: SET_PAYMENT_METHOD_FAIL,
  payload: error,
})

export const sendPaymentRequestEmail = data => ({
  type: SEND_PAYMENT_REQUEST_EMAIL,
  payload: data,
})

export const sendPaymentRequestEmailSuccess = data => ({
  type: SEND_PAYMENT_REQUEST_EMAIL_SUCCESS,
  payload: data,
})

export const sendPaymentRequestEmailFail = error => ({
  type: SEND_PAYMENT_REQUEST_EMAIL_FAIL,
  payload: error,
})

export const sendPaymentRequestEmailNonExpress = paymentRequest => ({
  type: SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
  payload: paymentRequest,
})

export const setPaymentRequestEmailNonExpressSuccess = paymentRequest => ({
  type: SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_SUCCESS,
  payload: paymentRequest,
})

export const setPaymentRequestEmailNonExpressFail = paymentRequest => ({
  type: SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_FAIL,
  payload: paymentRequest,
})

export const sendPaymentRequestEmailExpress = paymentRequest => ({
  type: SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
  payload: paymentRequest,
})

export const setPaymentRequestEmailExpressSuccess = paymentRequest => ({
  type: SET_PAYMENT_REQUEST_EMAIL_EXPRESS_SUCCESS,
  payload: paymentRequest,
})

export const setPaymentRequestEmailExpressFail = paymentRequest => ({
  type: SET_PAYMENT_REQUEST_EMAIL_EXPRESS_FAIL,
  payload: paymentRequest,
})

export const updatePaymentRequest = paymentRequest => ({
  type: UPDATE_PAYMNET_REQUEST,
  payload: paymentRequest,
})
export const updatePaymentRequestSuccess = paymentRequest => ({
  type: UPDATE_PAYMNET_REQUEST_SUCCESS,
  payload: paymentRequest,
})
export const updatePaymentRequestFail = paymentRequest => ({
  type: UPDATE_PAYMNET_REQUEST_FAIL,
  payload: paymentRequest,
})
export const updatePaymentRequestBoolean = paymentRequest => ({
  type: UPDATE_PAYMENT_REQUEST_BOOLEAN,
  payload: paymentRequest,
})

export const updatePaymentRequestBooleanSuccess = paymentRequest => ({
  type: UPDATE_PAYMENT_REQUEST_BOOLEAN_SUCCESS,
  payload: paymentRequest,
})
export const updatePaymentRequestBooleanFalse = paymentRequest => ({
  type: UPDATE_PAYMENT_REQUEST_BOOLEAN_FAIL,
  payload: paymentRequest,
})

export const restartState = paymentRequest => ({
  type: RESTART_STATE,
  payload: paymentRequest,
})
export const getPayReqByUserId = paymentRequest => ({
  type: GET_USER_PAYMENT_REQUESTS_BY_USERID,
  payload: paymentRequest,
})

export const getPayReqByUserIdSuccess = paymentRequest => ({
  type: GET_USER_PAYMENT_REQUESTS_BY_USERID_SUCCESS,
  payload: paymentRequest,
})

export const getPayReqByUserIdFail = paymentRequest => ({
  type: GET_USER_PAYMENT_REQUESTS_BY_USERID_FAIL,
  payload: paymentRequest,
})

export const fileUpload = files => ({
  type: FILE_UPLOAD,
  payload: files,
})

export const fileUploadSuccess = files => ({
  type: FILE_UPLOAD_SUCCESS,
  payload: files,
})

export const fileUploadFail = files => ({
  type: FILE_UPLOAD_FAIL,
  payload: files,
})

export const mainSearch = search => ({
  type: MAIN_SEARCH_FILTER,
  payload: search,
})

export const setPaymentRequestExpress = paymentrequest => ({
  type: SET_PAYMENT_REQUEST_EXPRESS,
  payload: paymentrequest,
})

export const setPaymentRequestExpressSuccess = paymentrequest => ({
  type: SET_PAYMENT_REQUEST_EXPRESS_SUCCESS,
  payload: paymentrequest,
})

export const setPaymentRequestExpressFail = paymentrequest => ({
  type: SET_PAYMENT_REQUEST_EXPRESS_FAIL,
  payload: paymentrequest,
})

export const setMyfatoorahInitiatePayment = data => ({
  type: SET_MYFATOORAH_INITIATE_PAYMENT,
  payload: data,
})

export const setMyfatoorahInitiatePaymentSuccess = data => ({
  type: SET_MYFATOORAH_INITIATE_PAYMENT_SUCCESS,
  payload: data,
})

export const setMyfatoorahInitiatePaymentFail = error => ({
  type: SET_MYFATOORAH_INITIATE_PAYMENT_FAIL,
  payload: error,
})

export const setDibsyInitiatePayment = data => ({
  type: SET_DIBSY_INITIATE_PAYMENT,
  payload: data,
})

export const setDibsyInitiatePaymentSuccess = data => ({
  type: SET_DIBSY_INITIATE_PAYMENT_SUCCESS,
  payload: data,
})

export const setDibsyInitiatePaymentFail = error => ({
  type: SET_DIBSY_INITIATE_PAYMENT_FAIL,
  payload: error,
})

export const setDibsyInitiateSessionApplepay = merchantId => ({
  type: SET_DIBSY_INITIATE_SESSION_APPLEPAY,
  merchantId,
})

export const setDibsyInitiateSessionApplepaySuccess = merchantId => ({
  type: SET_DIBSY_INITIATE_SESSION_APPLEPAY_SUCCESS,
  merchantId,
})

export const setDibsyInitiateSessionApplepayFail = error => ({
  type: SET_DIBSY_INITIATE_SESSION_APPLEPAY_FAIL,
  payload: error,
})

export const setPaymentTypes = data => ({
  type: SET_PAYMENT_TYPES,
  payload: data,
})

export const setDibsyRefund = data => ({
  type: SET_DIBSY_REFUND,
  payload: data,
})

export const setDibsyRefundSucess = data => ({
  type: SET_DIBSY_REFUND_SUCCESS,
  payload: data,
})
export const setDibsyRefundFail = error => ({
  type: SET_DIBSY_REFUND_FAIL,
  payload: error,
})
