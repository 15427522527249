import React, { useEffect, useState } from "react"
import { Col, FormGroup, Row, Button, Form } from "reactstrap"
function TransactionFeeSettings(props) {
  const {
    transaction,
    handleTransactionChange,
    handleTransactionDelete,
    selectedPg,
    transactionBasis,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
    indexPG,
  } = props
  const inputBasis = "basis" + indexPG + index
  const inputType = "type" + indexPG + index
  const inputValue = "value" + indexPG + index
  const [transactionType, SetType] = useState("Fixed")
  const [transactionValue, SetValue] = useState()
  function handleChange(changes) {
    handleTransactionChange(transaction.id, { ...transaction, ...changes })
  }
  //console.log(transactionBasis)
  useEffect(() => {
    // console.log(transaction)
    if (transaction) {
      if (parseFloat(transaction.fixed) !== 0) {
        SetType("Fixed")
        SetValue(transaction.fixed)
      }
      if (parseFloat(transaction.percentage) !== 0) {
        SetType("Percentage")
        SetValue(transaction.percentage)
      }
    }
    // console.log(transaction)
  }, [transaction])
  useEffect(() => {
    if (transactionType && transactionValue) {
      switch (transactionType) {
        case "Fixed":
          handleChange({ fixed: parseFloat(transactionValue), percentage: 0 })
          break
        case "Percentage":
          handleChange({ fixed: 0, percentage: parseFloat(transactionValue) })
          break
      }
    }
  }, [transactionType, transactionValue])
  return (
    <div
      style={{
        backgroundColor: "#b7c6e7",
        marginLeft: "20px",
        padding: "10px",
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="3">
            {/* TODO: pg get from db*/}
            <FormGroup>
              <label htmlFor="subject">Payment Type </label>
              <select
                className="custom-select"
                value={transaction.basis}
                name={inputBasis}
                onChange={e => handleChange({ basis: e.target.value })}
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                {transactionBasis?.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <small className="color-danger">
                {errors[inputBasis] && errors[inputBasis].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label htmlFor="subject">Type </label>
              <select
                value={transactionType}
                name={inputType}
                onChange={e => SetType(e.target.value)}
                className="form-control"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value={"Fixed"}>Fixed</option>
                <option value={"Percentage"}>Percentage</option>
              </select>
              <small className="color-danger">
                {errors[inputType] && errors[inputType].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label htmlFor="subject">Value </label>
              <Row>
                <Col sm={8}>
                  <input
                    value={transactionValue}
                    name={inputValue}
                    step="0.01"
                    onChange={e => SetValue(parseFloat(e.target.value))}
                    className="form-control"
                    type="number"
                    placeholder={"0"}
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                  />
                </Col>
                <Col sm={4} className="py-2 ml-0 pl-0 ">
                  {" "}
                  {transactionType === "Fixed" ? <b>QAR</b> : <b>%</b>}
                </Col>
              </Row>
              <small className="color-danger">
                {errors[inputValue] && errors[inputValue].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <label style={{ color: "#b7c6e7" }} htmlFor="subject">
                Delete{" "}
              </label>

              <Button
                onClick={e => handleTransactionDelete(transaction.id)}
                color="secondary"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete Transaction Fee{" "}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default TransactionFeeSettings
