import React, { useState } from "react"
import SimpleBar from "simplebar-react"
import { Col, Row, Table, Collapse, Button } from "reactstrap"
import dateFormat from "../../../helpers/commonhelpers/dateFormat"

const FifthSummary = props => {
  const {
    merchantDetails,
    userAdmin,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const [collapseContacts, setCollapseContacts] = useState(false)
  const [collapseNotification, setCollapseNotification] = useState(false)
  const [collapseOfficeIds, setCollapseOfficeIds] = useState(false)
  const [collapsePgs, setCollapsePgs] = useState(false)
  // const newRegistrationDate = new Date().toISOString()
  // merchantDetails.RegistrationDate = dateFormat(newRegistrationDate)
  return (
    <>
      <h4>Summary</h4>
      <p>
        Please review all the details. Click previous button if requires edit.
      </p>
      <Row>
        <Col lg={4}>
          <SimpleBar>
            <Table className="table-nowrap mb-0 table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">
                    <i className="bx bx-buildings text-success mr-1 fa-3x" />
                    <b style={{ color: "gray", fontSize: "1rem" }}>
                      Merchant Details
                    </b>
                  </th>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Merchant Name</b>
                  </td>
                  <td>{merchantDetails.merchantName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Merchant Type</b>
                  </td>
                  <td>{merchantDetails.type}</td>
                </tr>
                <tr>
                  <td>
                    <b>Status</b>
                  </td>
                  <td>
                    {merchantDetails.isActive === true ? "Active" : "Inactive"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Registration Date</b>
                  </td>

                  <td>{dateFormat(merchantDetails.registrationDate)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Website</b>
                  </td>
                  <td>{merchantDetails.website}</td>
                </tr>
                <tr>
                  <td>
                    <b>Terms & Condition</b>
                  </td>
                  <td>{merchantDetails.termsConditionUrl}</td>
                </tr>
                <tr>
                  <td>
                    <b>Telephone</b>
                  </td>
                  <td>{merchantDetails.phoneNumber}</td>
                </tr>
                <tr>
                  <td>
                    <b>Business Registration No</b>
                  </td>
                  <td>{merchantDetails.businessRegNumber}</td>
                </tr>
                <tr>
                  <td>
                    <b>Tax Registration No</b>
                  </td>
                  <td>{merchantDetails.taxRegNumber}</td>
                </tr>
                <tr>
                  <td>
                    <b>Address</b>
                  </td>
                  <td>{merchantDetails.address}</td>
                </tr>
                <tr>
                  <td>
                    <b>City</b>
                  </td>
                  <td>{merchantDetails.city}</td>
                </tr>
                <tr>
                  <td>
                    <b>Country</b>
                  </td>
                  <td>{merchantDetails.country}</td>
                </tr>
                <tr>
                  <td>
                    <b>Po Box</b>
                  </td>
                  <td>{merchantDetails.poBox}</td>
                </tr>
              </tbody>
            </Table>
          </SimpleBar>
        </Col>

        <Col lg={3}>
          <Col lg={12}>
            <SimpleBar>
              <Table className="table-nowrap mb-0 table table-borderless">
                <tbody>
                  <tr>
                    <td>
                      <b>Link Validity</b>
                    </td>
                    <td>
                      {merchantDetails?.paymentGatewaySettings?.linkValidity}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Marketing Banner</b>
                    </td>
                    <td>
                      {" "}
                      <div className="color-primary">
                        <a href={merchantDetails.banner} target="_blank">
                          banner link!
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Logo</b>
                    </td>
                    <td>
                      {" "}
                      <div className="color-primary">
                        <a href={merchantDetails.logo} target="_blank">
                          logo link!
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <i className="bx bx-user-circle text-success mr-1 fa-3x" />
                      <b style={{ color: "gray", fontSize: "1rem" }}>
                        Admin User
                      </b>
                    </th>
                    <td></td>
                  </tr>

                  <tr>
                    <td>
                      <b>Title</b>
                    </td>
                    <td>{userAdmin.title}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>First Name</b>
                    </td>
                    <td>{userAdmin.firstName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Last Name</b>
                    </td>
                    <td>{userAdmin.lastName}</td>
                  </tr>
                  {/* <tr>
                                        <td><b>UserName</b></td>
                                        <td>{userAdmin.UserName}</td>
                                    </tr> */}
                  <tr>
                    <td>
                      <b>Email</b>
                    </td>
                    <td>{userAdmin.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Mobile</b>
                    </td>
                    <td>{userAdmin.mobile}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Department</b>
                    </td>
                    <td>{userAdmin.department}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Role</b>
                    </td>
                    <td>{userAdmin.role}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Status</b>
                    </td>
                    <td>{userAdmin?.isActive?.toString()}</td>
                  </tr>
                </tbody>
              </Table>
            </SimpleBar>
          </Col>
        </Col>
        <Col lg={5}>
          <Col lg={12}>
            <SimpleBar>
              <Table className="table-nowrap mb-0 table table-borderless">
                <tbody>
                  <tr>
                    <th scope="row">
                      <i className="bx bx-cog text-success mr-1 fa-3x" />
                      <b style={{ color: "gray", fontSize: "1rem" }}>
                        Settings & Configurations
                      </b>
                    </th>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        onClick={() => {
                          setCollapseContacts(!collapseContacts)
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        {" "}
                        <b>Contact Details</b> (Click to Show)
                      </span>

                      <Collapse isOpen={collapseContacts}>
                        <SimpleBar>
                          <Table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Name</b>
                                </td>
                                <td>
                                  <b>Mobile</b>
                                </td>
                                <td>
                                  <b>Email</b>
                                </td>
                                <td>
                                  <b>Position</b>
                                </td>
                              </tr>

                              {merchantDetails?.contacts?.map(
                                (contact, index) => (
                                  <tr key={index}>
                                    <td>{contact.name}</td>
                                    <td>{contact.mobile}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.position}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </SimpleBar>
                      </Collapse>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span
                        onClick={() => {
                          setCollapseNotification(!collapseNotification)
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        <b>Notification Emails</b> (Click to Show)
                      </span>

                      <Collapse isOpen={collapseNotification}>
                        {merchantDetails?.notificationEmails?.map(
                          (email, index) => (
                            <p key={index}>{email.email}</p>
                          )
                        )}
                      </Collapse>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span
                        onClick={() => {
                          setCollapseOfficeIds(!collapseOfficeIds)
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        <b>Registered OfficeIds</b> (Click to Show)
                      </span>

                      <Collapse isOpen={collapseOfficeIds}>
                        {merchantDetails?.officeIds?.map((oid, index) => (
                          <p key={index}>{oid.officeId}</p>
                        ))}
                      </Collapse>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span
                        onClick={() => {
                          setCollapsePgs(!collapsePgs)
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        {" "}
                        <b>Enrolled Payment Gateway/s</b> (Click to Show)
                      </span>

                      <Collapse isOpen={collapsePgs}>
                        <SimpleBar>
                          <Table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>PaymentGateway</b>
                                </td>
                                <td>
                                  <b>Custom Config ( KEY )</b>
                                </td>
                                <td>
                                  <b>Custom Config ( VALUE ) </b>
                                </td>
                                <td>
                                  <b>IsActive</b>
                                </td>
                                <td>
                                  <b>Commission</b>
                                </td>
                                <td>
                                  <b>Transaction Fee</b>
                                </td>
                                <td>
                                  <b>Transaction Value</b>
                                </td>
                              </tr>

                              {merchantDetails?.paymentGatewayEnrollments?.map(
                                (pg, index) => (
                                  <tr key={index}>
                                    <td>{pg?.paymentGateway}</td>
                                    <td>
                                      {pg?.customConfig
                                        ?.map(x => x.key)
                                        .join(" , ")}
                                    </td>
                                    <td>
                                      {pg?.customConfig
                                        ?.map(x => x.value)
                                        .join(" , ")}
                                    </td>

                                    <td>
                                      {pg.isActive ? "ACTIVE" : "DEACTIVATED"}
                                    </td>
                                    <td>
                                      {pg?.commissionSettings?.map(
                                        x => x.value
                                      ) -
                                        pg?.commissionSettings?.map(
                                          x => x.type
                                        )}
                                    </td>
                                    <td>
                                      {pg?.transactionFee
                                        ?.map(x => x?.basis)
                                        .join(" , ")}
                                    </td>
                                    <td>
                                      {" "}
                                      {pg?.transactionFee
                                        ?.map(x =>
                                          parseFloat(x?.fixed) !== 0
                                            ? x?.fixed + " QAR"
                                            : x?.percentage + " %"
                                        )
                                        .join(" , ")}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </SimpleBar>
                      </Collapse>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </SimpleBar>
          </Col>
        </Col>
      </Row>

      <ul className="pager wizard twitter-bs-wizard-pager-link">
        <li
          className={activeTabProgress === 1 ? "previous disabled" : "previous"}
        >
          <Button
            color="primary"
            onClick={() => {
              toggleTabProgress(activeTabProgress - 1)
            }}
          >
            {" "}
            Previous{" "}
          </Button>
        </li>
        <li className={activeTabProgress === 5 ? "next" : "next"}>
          {activeTabProgress === 5 ? (
            <>{loadSubmitNowButton()}</>
          ) : (
            <Button type="submit" color="primary">
              {" "}
              Next{" "}
            </Button>
          )}
        </li>
      </ul>
    </>
  )
}

export default FifthSummary
