import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { getMerchantDetail } from "store/actions"
import { getUserListByMerchantId } from "store/actions"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Contacts from "./Contacts"
import MerchantInfo from "./MerchantInfo"
import NotificationEmails from "./NotificationEmails"
import OfficeIds from "./OfficeIds"
import PaymentGatewayEnrollments from "./PaymentGatewayEnrollments"
import Configuration from "./Configuration"
import TransactionFee from "./TransactionFee"
import Users from "./Users"
import AuthUser from "components/AuthUser/AuthUser"
import { Link } from "react-router-dom"

export const MerchantOverview = props => {
  //TODO: edit and delete

  const {
    onGetMerchantDetail,
    onGetUserList,
    merchantDetails,
    userList,
    loading,
    loadingUserList,
    // merchantUpdated,
    match: { params },
  } = props

  const [isLoaded, setIsLoaded] = useState()
  const [user, setUser] = useState(AuthUser())
  const [merchantDetail, setMerchantDetail] = useState()

  useEffect(() => {
    if (user) {
      // console.log(params.id)
      if (user.role === "SuperAdmin") {
        if (params && params.id) {
          onGetMerchantDetail(params.id)
          onGetUserList(params.id)
        }
      } else {
        if (user.merchantId) {
          onGetMerchantDetail(user.merchantId)
        }
      }
    }
  }, [params, onGetMerchantDetail])
  // useEffect(() => {
  //   console.log(merchantUpdated)
  //   if (merchantUpdated && merchantUpdated === true) {
  //     showToastSuccess("Merchant successfully updated!")
  //   }
  //   if (merchantUpdated && merchantUpdated === false) {
  //     showToastError("Merchant not updated!")
  //   }
  //   resetState()
  // }, [merchantUpdated])
  useEffect(() => {
    setIsLoaded(loading)
  }, [loading])

  useEffect(() => {
    if (
      user?.role === "SuperAdmin" &&
      merchantDetails &&
      params.id === merchantDetails.merchantId
    ) {
      setMerchantDetail(merchantDetails)
    }
    if (user?.role === "Admin" && params.id === "0") {
      setMerchantDetail(merchantDetails)
    }
  }, [merchantDetails])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Overview " breadcrumbItem=" MY BUSINESS" />

          {isLoaded ? (
            <LoadingSpinnerThreeDots />
          ) : (
            !isEmpty(merchantDetail) && (
              <>
                <Row>
                  {user[0]?.role !== "User" ? (
                    <Col lg="12" className=" my-2">
                      {" "}
                      <Link
                        to={"/merchant/edit/" + merchantDetail.merchantId}
                        className="mr-2 mb-1 float-right"
                      >
                        <i
                          className="mdi mdi-pencil  font-size-18 mr-1 "
                          id="edittooltip"
                        />
                        Update Merchant
                      </Link>
                    </Col>
                  ) : null}
                  <Col lg="12">
                    <MerchantInfo merchantDetail={merchantDetail} />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {!isEmpty(merchantDetail.contacts) && (
                      <Contacts contacts={merchantDetail?.contacts} />
                    )}
                  </Col>

                  <Col lg="6">
                    <PaymentGatewayEnrollments
                      paymentGatewayEnrollments={
                        merchantDetail?.paymentGatewayEnrollments
                      }
                    />
                  </Col>
                </Row>
                {merchantDetail?.paymentGatewayEnrollments[0]
                  ?.transactionFee ? (
                  <Row>
                    <Col lg="12">
                      <TransactionFee
                        paymentGatewayEnrollments={
                          merchantDetail?.paymentGatewayEnrollments
                        }
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  {merchantDetail?.type === "Online Retail" ? (
                    <Col lg="12">
                      <Configuration
                        linkValidity={
                          merchantDetail?.paymentGatewaySettings?.linkValidity
                        }
                        marketingBanner={merchantDetail?.banner}
                        logo={merchantDetail?.logo}
                      />
                    </Col>
                  ) : (
                    <>
                      <Col lg="4">
                        <NotificationEmails
                          notificationEmails={
                            merchantDetail?.notificationEmails
                          }
                        />
                      </Col>
                      <Col lg="4">
                        <OfficeIds officeIds={merchantDetail?.officeIds} />
                      </Col>
                      <Col lg="4">
                        <Configuration
                          linkValidity={
                            merchantDetail?.paymentGatewaySettings?.linkValidity
                          }
                          marketingBanner={merchantDetail?.banner}
                          logo={merchantDetail?.logo}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {/* {user?.role === "SuperAdmin" && (
                  <Row>
                    <Col lg="12">
                    {!isEmpty(userList) && (
                      <Users usersByMerchant={userList} />
                    )}
                    </Col>
                  
                  </Row>
                )} */}
              </>
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

MerchantOverview.propTypes = {
  userList: PropTypes.any,
  merchantDetails: PropTypes.object,
}

const mapStateToProps = state => ({
  merchantDetails: state.Merchants.merchantDetail,
  loading: state.Merchants.loading,
  userList: state.Users.usersList,
  loadingUserList: state.Users.loading,
  // merchantUpdated: state.Merchants.merchantUpdated,
})

const mapDispatchToProps = dispatch => ({
  onGetMerchantDetail: id => dispatch(getMerchantDetail(id)),
  onGetUserList: id => dispatch(getUserListByMerchantId(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantOverview)
