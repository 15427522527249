import React from "react"
import { Link } from "react-router-dom"
import { Button, UncontrolledTooltip } from "reactstrap"
import { statusPillsBool } from "../../../helpers/commonhelpers/statusPills"

const TransactionFeeColumns = () => {
  //   const isHidden = () => {
  //     if (user.role === "Admin") return true
  //     else return false
  //   }

  return [
    {
      dataField: "basis",
      text: "Card Type",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span>
            <b>{cellContent}</b>
          </span>
        )
      },
    },
    {
      text: "Type",
      sort: true,
      formatter: (cellContent, row) => {
        if (parseFloat(row?.fixed) !== 0) {
          return <span>Fixed</span>
        } else {
          return <span>Percentage</span>
        }
      },
    },
    {
      text: "Value",
      sort: true,
      formatter: (cellContent, row) => {
        if (parseFloat(row?.fixed) !== 0) {
          return <span>{row.fixed} QAR</span>
        } else {
          return <span>{row.percentage} %</span>
        }
      },
    },
    // {
    //   dataField: "fixed",
    //   text: "Fixed",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return <span>{cellContent} QAR</span>
    //   },
    // },

    // {
    //   dataField: "percentage",
    //   text: "Percentage",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return <span>{cellContent} %</span>
    //   },
    // },

    //   {
    //     dataField: "isActive",
    //     text: "isActive",
    //     sort: true,
    //     formatter: (cellContent, row) => {
    //         return <span>

    //           { statusPillsBool(cellContent,cellContent.toString(),true)}

    //         </span>
    //       }
    //   },

    //   {
    //     dataField: "commissionSettings",
    //     text: "More ",
    //     sort: true,
    //     hidden: isHidden(),
    //     formatter: (cellContent,row,rowIndex) => (
    //       <Button
    //         type="button"
    //         color="primary"
    //         className="btn-sm btn-rounded"
    //         onClick={() => handleCommissionOverview(rowIndex)}
    //       >
    //         View Details
    //       </Button>
    //     ),
    //   },

    // {
    //   dataField: "basis",
    //   text: "Action",
    //   formatter: () => (
    //     <>
    //       <Link to="#" className="mr-3 text-primary">
    //         <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip" />
    //         <UncontrolledTooltip placement="top" target="edittooltip">
    //           Edit
    //         </UncontrolledTooltip>
    //       </Link>
    //       <Link to="#" className="text-danger">
    //         <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip" />
    //         <UncontrolledTooltip placement="top" target="deletetooltip">
    //           Delete
    //         </UncontrolledTooltip>
    //       </Link>
    //     </>
    //   ),
    // },
  ]
}

export default TransactionFeeColumns
